import React, {useCallback, useEffect, useState} from "react";
import { getDocumentListSize} from "../modules/constVariable";
import { useTranslation } from "react-i18next";
import * as constVariable from "../modules/constVariable";
import Cookies from "js-cookie";
import DocListDocList from "./DocListDocList";
import "../styles/NewHomepage/SearchDocs.css"
import "../styles/Rest/ScrollBar.css"
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import * as util from "../modules/util";
import * as documentManager from "../modules/documentManager"
import useResizeObserver from "use-resize-observer";
// next import is needed for pdfjs to work even though it is declared as "not used in the code"
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

/**
 * This component represents the document list.
 * @param isLightMode - The light mode.
 * @param searchQuery - The search query.
 * @returns {JSX.Element} - The document list.
 */
const DocList = ({ isLightMode, searchQuery }) => {
    
    // Define the state variables
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    let [pdfpath, setPdfpath] = useState('');
    let [pdfID, setpdfID] = useState('');
    const { t } = useTranslation();
    const [ doclistheight, setDoclistheight ] = useState(0);
    const [containerRef, setContainerRef] = useState();
    const [containerWidth, setContainerWidth] = useState();
    const maxWidth = 650;
    const resizeObserverOptions = {};

    // Define the resize observer callback
    const onResize = useCallback((entries) => {
        const [entry] = entries;
    
        if (entry) {
          setContainerWidth(entry.contentRect.width);
        }
    }, []);
    // Use the resize observer hook
    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    // Define the function to handle the document load success
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }
    
    // Define the function to change the page
    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    // Define the function to go to the previous page
    function previousPage() {
        changePage(-1);
    }
    
    // Define the function to go to the next page
    function nextPage() {
        changePage(1);
    }

    /**
     * This function reads the document list size from the cookie and sets the variable and slider to the value.
     */
    useEffect(() => {
        // Get the documentListSize cookie
        let cookieListSize = Cookies.get("documentListSize");

        // If the documentListSize cookie exists, set the documentListSize based on it
        if(cookieListSize){
            constVariable.setDocumentListSize(cookieListSize);
            document.querySelector(".size-slider-input").value = cookieListSize;
        }else{
            document.querySelector(".size-slider-input").value = constVariable.getDefaultValues().documentListSize;
            constVariable.setDocumentListSize(constVariable.getDefaultValues().documentListSize);
        }
    });

    /**
     * Changes the size of the document list cells, by changing the width of the cell.
     * @param size - The size to be set.
     */
    function changeDocumentListSize(size) {
        // get all the cells
        let cell = document.getElementsByClassName("documentList");
        // change the size of the cells
        for (let i = 0; i < cell.length; i++) {
            cell[i].style.width = size + "px";
        }
        // save the size in the global variable
        constVariable.setDefaultValuesDocumentListSize(size);
        constVariable.setDocumentListSize(size);
        // save the size in a cookie
        setDocumentListSizeCookie(size);
    }

    /**
     * Sets the document list size in a cookie.
     * @param {string} size - The size to be set.
     * @param {number} daysToLive - The number of days the cookie should live (has Default value of '0').
     * @param {string} path - The path of the cookie (has Default value of '/').
     */
    const setDocumentListSizeCookie = (size, daysToLive = 0, path = "/") => {
        // create the cookie value
        let cookieValue = "documentListSize=" + size;

        // set the expiration date of the cookie
        if (daysToLive) {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + daysToLive);
            cookieValue += '; expires=' + expirationDate.toUTCString();
        }

        // set the path of the cookie
        if (path) {
            cookieValue += '; path=' + path;
        }

        // set SameSite attribute to "None" for cross-origin sharing
        cookieValue += '; SameSite=strict; Secure';
        // set the cookie
        document.cookie = cookieValue;
    };

    // Set the document list size
    useEffect(() => {
        const handleResize = () => {
            // Get the elements
            const sdcElement = document.querySelector('.search-docs-container');
            const dsbElement = document.querySelector('.doc-search-bar');
            const ilElement = document.querySelector('.fa-info-line');
            const dlElement = document.querySelector('.doc-list-toolbar');

            // Check if the elements exist
            if (!sdcElement || !dsbElement || !ilElement || !dlElement) {
                console.log("One or more elements not found.");
                return;
            }

            // Calculate the height of the doclist
            const sdcheight = sdcElement.offsetHeight;
            const sdcpaddingt = parseInt(window.getComputedStyle(sdcElement).marginTop);
            const sdcpaddingb = parseInt(window.getComputedStyle(sdcElement).paddingBottom);
            const dsbheight = dsbElement.offsetHeight;
            const ilheight = ilElement.offsetHeight;
            const dlheight = dlElement.offsetHeight;

            // set the new height of the doclist
            const newdoclistheight = sdcheight - sdcpaddingt - sdcpaddingb - dsbheight - ilheight - dlheight;
            setDoclistheight(newdoclistheight);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Call handleResize initially
        handleResize();

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Render the document list
    return (
        <div className="doc-list">
            <div className="doc-list-toolbar docBox">
                {/* image size slider */}
                <div className="size-slider">
                    <div className="sliderText">-</div>
                    <input
                        className="size-slider-input"
                        id="tmp"
                        name="tmp"
                        type="range"
                        width="10%"
                        min="100"
                        max="500"
                        step="100"
                        list="markers"
                        defaultValue={constVariable.getDefaultValues().documentListSize}
                        onInput={(e) => {
                            changeDocumentListSize(e.target.value);
                            document.querySelectorAll(".doc-tile-img").forEach((img) => {img.style.height = getDocumentListSize() + "px";});
                        }}
                    />
                    <datalist id="markers">
                        <option value="100"></option>
                        <option value="200"></option>
                        <option value="300"></option>
                        <option value="400"></option>
                        <option value="500"></option>
                    </datalist>
                    <div className="sliderText">+</div>
                </div>
            </div>
            {/* List container*/}
            <div className="list-container docBox docBox-lower doc-list-container"
                style={{maxHeight: `${doclistheight}px`}}>
                <DocListDocList setPdfpath={setPdfpath} setpdfID={setpdfID} searchQuery={searchQuery}/>
            </div>
            <div className="modal fade popupPDFmodal setThemeModeText" id="popupPDF" tabIndex="-1" aria-labelledby="popupPDFLabel" aria-hidden="true" data-bs-theme={!isLightMode ? "light" : "dark"}>
                <div className="modal-dialog modal-dialog-centered modal-content-custom2">
                    <div className="modal-content-custom">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">{util.translate("PDF")}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body docBox adminProperties" ref={setContainerRef}>
                            {pdfpath && (<Document
                                file={pdfpath}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page
                                    pageNumber={pageNumber}
                                    renderAnnotationLayer = {false}
                                    renderTextLayer = {false}
                                    width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                />
                            </Document>)}
                        </div>
                        <div>
                            <div>
                                <p style={{marginBottom:"0"}}>
                                    {util.translate("page")} {pageNumber || (numPages ? 1 : '--')} {util.translate("of")} {numPages || '--'}
                                </p>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                >
                                    {util.translate("previous")}
                                </button>
                                <button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    {t("next")}
                                </button>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => {const pdfWindow = window.open(); documentManager.openPDFWindow(pdfID, pdfWindow)}}>{t("open")}</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DocList;