import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

// Bye component that displays a goodbye message
const Bye = () => {
    const { t } = useTranslation();
    
    // return the JSX for the Bye component
    return (
        <div>
            <h1>{t("bye-heading")}</h1>
            <br />
            <p>{t("bye-message")}</p>
            <br />
            <br />
            <Link to="/">{t("bye-return")}</Link>
        </div>
    );
};

export default Bye;