import React, {useEffect, useState} from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Dictaphone from "./Dictaphone.jsx";
import { useLocation, useNavigate } from "react-router";
import * as util from "../modules/util.js";
import ListFAs from "../LoadHomePage/ListFA.jsx";
import { microphoneTimeout } from "../modules/constVariable.js";

/**
 * This component represents the FA search bar.
 * @param {number} FA_ID_maxInput - The maximum input length for the FA ID.
 * @param {string} faid - The FA ID.
 * @param {boolean} isLightMode - The light mode status.
 * @param {boolean} isListening - The listening status.
 * @param {function} setIsListening - The function to set the listening status.
 * @param {boolean} showRecentSearches - The recent searches status.
 * @param {function} setShowRecentSearches - The function to set the recent searches status.
 * @param {object} searchRef - The search reference.
 * @param {string[]} recentSearches - The recent searches.
 * @param {function} setRecentSearches - The function to set the recent searches.
 * @returns The FaSearchBar component.
 */
const FaSearchBar = ({ FA_ID_maxInput, faid, isLightMode, isListening, setIsListening, showRecentSearches,
    setShowRecentSearches, searchRef, recentSearches, setRecentSearches }) => {

    // Constants declaration
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    // State to hold the height of the FA-list
    const [ faListHeight, setFaListHeight ] = useState(0);

    // useEffect to handle window resize
    useEffect(() => {
        const handleResize = () => {
            // Check if the elements exist in the DOM
            const btlElement = document.querySelector('.box-top-left');
            const sFATElement = document.querySelector('.s-FA-t');
            const FAsbElement = document.querySelector('.fa-search-bar');
            const rlElement = document.querySelector('.recent-list');

            // Check if the elements exist in the DOM
            if (!FAsbElement || !rlElement || !btlElement || !sFATElement) {
                console.log("One or more elements not found.");
                return;
            }

            // Calculate the new height for FA-list
            const btlHeight = btlElement.offsetHeight;
            const sFATHeight = sFATElement.offsetHeight;
            const sFATMargin = parseInt(window.getComputedStyle(sFATElement).marginBottom);
            const FAsbHeight = FAsbElement.offsetHeight;
            const FAsbMarginB = parseInt(window.getComputedStyle(FAsbElement).marginBottom);
            const rlborderT = parseInt(window.getComputedStyle(rlElement).borderTopWidth);
            const rlborderB = parseInt(window.getComputedStyle(rlElement).borderBottomWidth);

            // Set the new height for FA-list
            const newFaListHeight = btlHeight - sFATHeight - sFATMargin - FAsbHeight - FAsbMarginB - rlborderB - rlborderT;
            setFaListHeight(newFaListHeight);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Call handleResize initially
        handleResize();

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /**
     * This function handles the location change via browser history and
     * FA search on page load if the FA ID is in the URL.
     */
    useEffect(() => {
        // handle location change via browser history
        if (faid !== undefined &&
            faid !== document.getElementById("input_fa").value) {
            sendSearch(null, faid, false);
        } else {
            // clear the document list
            document.querySelector(".current-FA").innerHTML = "";
            document.querySelector(".NVFA").style.display = "none";
            document.querySelector(".current-Status").innerHTML = "";
            document.querySelector(".NDF").style.display = "none";
            util.clearTable();

            //clear the FA search field
            document.querySelector(".inp").value = "";
            setFaIdList(null);
        }
        // if faid param in URL, search on page load
        if (faid !== undefined) {
            sendSearch(null, faid, false);
        }
    }, [location, faid]);

    /**
     * This function loads the recent search queries from the cookie and sets them in the state.
     */
    useEffect(() => {
        // at the start, load the saved search queries
        setRecentSearches(loadRecentSearchesFromCookie());
        // use the loaded search queries or an empty array if none are present
        // example: console.log(recentSearches);

        // add event listener to hide the search history when clicked outside the search field
        /**
         * This function handles the click outside the search field and closes the recent searches.
         * @param event - The event object.
         */
        const handleClickOutside = (event) => {
            if (searchRef.current &&
                !searchRef.current.contains(event.target)) {
                setShowRecentSearches(false);
            }
        };
        // add event listener to hide the search history when clicked outside the search field  
        document.addEventListener("mousedown", handleClickOutside);

        // remove event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    /**
     * This function sends the search query to the backend and generates the document list.
     * @param e - The event object.
     * @param searchQuery - The search query to be sent.
     * @param nav - If the navigation should be done. Default is true.
     */
    const sendSearch = (e, searchQuery, nav=true) => {
        // get the search query
        let FA = searchQuery;

        // check if FA is not empty
        if (FA != "") {
            if(e !== null)
                e.preventDefault();
            
            if (nav)
                navigate("/fa/" + FA);
        }

        // check if FA is a valid FA
        let validateFA = (FA) => {
            return FA.match(/^\d+$/);
        };

        if (validateFA(FA)) {
            // valid FA
            // get documents and generate list
            util.getDocuments(FA);

            // add search to recent searches
            addSearchToRecentSearches(FA);
            setShowRecentSearches(false);
        } else {
            // not a valid FA
            // set NOT A VALID FA in the header
            document.querySelector(".current-FA").innerHTML = "";
            document.querySelector(".NVFA").style.display = "inline";
            document.querySelector(".current-Status").innerHTML = "";
            // hide the NDF message if it is displayed
            document.querySelector(".NDF").style.display = "none";
            // clear the table
            util.clearTable();
        }
    };

    // State to hold the FA ID list
    const [faIdList, setFaIdList] = useState();

    /**
     * This function sends the search query to the backend and generates the document name list based on a partial search query.
     * @param e - The event object.
     * @param searchQuery - The search query to be sent.
     * @param nav - If the navigation should be done. Default is true.
     */
    const sendSearchList = (e, searchQuery, nav=true) => {
        // disable the microphone
        disableMicrophone();
        // get the search query
        let FA = searchQuery;

        // check if FA is not empty
        let validateFA = (FA) => {
            return FA.match(/^\d+$/);
        };

        // check if FA is a valid FA
        if (validateFA(FA)) {
            setFaIdList(FA);
            addSearchToRecentSearches(FA);
            setShowRecentSearches(false);
            document.querySelector(".inp").value = FA;
        }
        else {
            setFaIdList(null);
        }
    };

    /**
     * Handles the click on a recent search query.
     * @param search - The search query that was clicked.
     * @param e - The event object.
     * @returns {void} - Returns nothing.
     */
    const handleRecentSearchClick = (search, e) => {
        // if a past search query was selected, insert it into the search field
        setShowRecentSearches(true);
        // do the search for the selected search query
        sendSearch(e, search); // give the event object to sendSearch
    };

    /**
     * Handles the click on the search field.
     * @returns {void} - Returns nothing.
     */
    const handleSearchClick = () => {
        // on click of the search field, show the recent search queries
        setShowRecentSearches(true);
    };
    
    /**
     * Adds the search query to the recent searches in the cookie.
     * @param FA - The search query to be added.
     */
    const addSearchToRecentSearches = (FA) => {
        // get the recent searches from the cookie
        let recentSearches = loadRecentSearchesFromCookie();

        // add the search query to the recent searches
        if (!recentSearches.includes(FA)) {
            recentSearches.unshift(FA);
            recentSearches = recentSearches.slice(0, 5);
            document.cookie = "recentSearches=" + JSON.stringify(recentSearches);
            setRecentSearches(recentSearches);
        }
    };

    /**
     * Loads the recent search queries from the cookie.
     * @returns {any[]|*[]} - Returns the recent search queries or an empty array if none are present.
     */
    const loadRecentSearchesFromCookie = () => {
        // get the recent searches from the cookie
        const cookieValue = document.cookie.replace(
            /(?:^|.*;\s*)recentSearches\s*=\s*([^;]*).*$|^.*$/,
            "$1"
        );
        const searches = cookieValue ? JSON.parse(cookieValue) : [];
        // make sure recentSearches is always an array, even if it's empty
        return Array.isArray(searches) ? searches : [];
    };

    /**
     * This function disables the microphone and shows a toast message.
     * @param {boolean} isTimeout - If the timeout is running.
     */
    const disableMicrophone = (isTimeout) => {
        if(isTimeout || isTimeoutRunning){
            stopTimeout();
            util.showToast(t("microphonestateOff"), util.ToastType.INFO);
            setIsListening(false);
            document.getElementsByClassName("microphone-icon")[0].src = isLightMode ? process.env.PUBLIC_URL + "/assets/icons/mic_white.svg" : process.env.PUBLIC_URL + "/assets/icons/mic.svg";
        }
    }

    /**
     * This function enables the microphone and shows a toast message.
     */
    const enableMicrophone = () => {
        startTimeout();
        setIsListening(true);
        document.getElementsByClassName("microphone-icon")[0].src = process.env.PUBLIC_URL + "/assets/icons/mic_on.svg";
        util.showToast(t("microphonestateOn"), util.ToastType.INFO);
    }

    // State to hold the microphone stop timer
    const [microphoneStopTimer, setMicrophoneStopTimer] = useState(null);
    const [isTimeoutRunning, setIsTimeoutRunning] = useState(false);

    /**
     * This function starts a timeout to disable the microphone after 5 seconds.
     */
    const startTimeout = () => {
        const id = setTimeout(() => {
            disableMicrophone(true);
        }, microphoneTimeout);
        setMicrophoneStopTimer(id);
        setIsTimeoutRunning(true);        
    };

    /**
     * This function stops the timeout to disable the microphone.
     */
    const stopTimeout = () => {
        clearTimeout(microphoneStopTimer);
        setIsTimeoutRunning(false);
    };

    // useEffect to handle the microphone icon change
    useEffect(() => {
        if(!isTimeoutRunning){
            if(!isLightMode){
                document.getElementsByClassName("microphone-icon")[0].src = process.env.PUBLIC_URL + "/assets/icons/mic.svg";
            }else{
                document.getElementsByClassName("microphone-icon")[0].src = process.env.PUBLIC_URL + "/assets/icons/mic_white.svg";   
            }
        }
        else{
            disableMicrophone(true);
        }
    }, [isLightMode]);

    // useEffect to handle the microphone icon change
    return (
        <div>
            <div className="fa-search-bar wrapper">
                <div className="prefix">
                    {t("FA")}
                </div>
                <div className="just-search-bar-fa">
                    {/* FA Search form */}
                    <form className="input-group search-bar-zindex"
                          onSubmit={(e) => {
                              e.preventDefault()
                              sendSearchList(e, document.querySelector(".inp").value)
                          }}
                    >
                        <input id="input_fa"
                               type="text"
                               maxLength={FA_ID_maxInput}
                               placeholder={12345678}
                               defaultValue={faid}
                               className="inp"
                               onFocus={handleSearchClick}
                               onWheel={(e) => e.target.blur()}
                               onChange={(e) => {
                                   // remove all non-numeric characters and update the input field
                                   e.target.value = e.target.value.replace(/\D/g, "");
                               }}
                        />
                    </form>
                    <Dictaphone
                        languageMode={i18next.language === 'de' ? 'de-DE' : 'en-GB'}
                        onListeningModeChange={(xyz) => {
                            setIsListening(xyz);
                            document.querySelector(".inp").focus()
                        }
                        }
                        isListening={isListening}
                        onSearch={() => {
                            sendSearchList(null, document.querySelector(".inp").value)
                        }}
                        onChange={(transcript) => {
                            document.getElementById("input_fa").value += transcript.replace(/\D/g, "");
                        }}
                        onResetValue={() => {
                            document.getElementById("input_fa").value = ""
                        }}
                        onDeleteChar={() => {
                            document.getElementById("input_fa").value = document.getElementById("input_fa").value.slice(0, -1)
                        }}
                    />
                    <button className="microphone-button" type="button" onClick={
                        () => {
                            setIsListening(!isListening);
                            if (isListening) {
                                disableMicrophone(false);
                            } else {
                                enableMicrophone();
                            }
                        }}>
                        <img src={process.env.PUBLIC_URL + "/assets/icons/mic.svg"} alt="microphone"
                             className="microphone-icon"/>

                    </button>
                </div>
                <div className="suffix"
                     onClick={(e) => sendSearchList(e, document.querySelector(".inp").value)/* sendSearch(e, document.querySelector(".inp").value) */}>
                    {t("suche")}
                </div>
                {/* Recent searches dropdown */}
                {showRecentSearches && (
                    <div className="recentSearches" ref={searchRef}>
                        <ul>
                            {recentSearches.map((search, index) => (
                                <li className="recentSearchLi"
                                    key={index}
                                    onClick={(e) => handleRecentSearchClick(search, e)}>
                                    {search}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            <div style={{ maxHeight: `${faListHeight}px` }}
                className="FA-list docBox">
                {/* FA List */}
                <ListFAs faId={faIdList}/>
            </div>
        </div>
    );
}

export default FaSearchBar;