export const baseurl = "https://dev.dokview.com/api/v1"; //"http://localhost:8080/api/v1";
// global variables
// current displayed data
// cell size for the document list
// recently displayed data for faster access
// current index for the preview
// max size for the preview
export let  recentData = [];
let currentIndexForPreview = 1;
export let previewMaxSize = 4;
// for the preview slider show
let documentListSize = 200, currentData = [];
let refreshCurrentData = new Event('refreshCurrentData');

/**
 * This function sets the Current Data.
 * @param data {Array} - The Current Data.
 */
export function setCurrentData(data) {
    currentData = data;
    // dispatch the event
    window.dispatchEvent(refreshCurrentData);
}

/**
 * This function returns the Current Data.
 * @returns {Array} - Returns the Current Data.
 */
export function getCurrentData() {
    return currentData;
}

/**
 * This function sets the current index for the preview.
 * @param data {number} - The index to be set.
 */
export function setCurrentIndexForPreview(data) {
    currentIndexForPreview = data;
}

/**
 * This function returns the current index for the preview.
 * @returns {number} - Returns the current index for the preview.
 */
export function getCurrentIndexForPreview() {
    return currentIndexForPreview;
}

/**
 * This function sets the size for the preview items.
 * @param size {number} - The size to be set.
 */
export function setDocumentListSize(size) {
    documentListSize = size;
}

/**
 * This function returns the size for the preview items.
 * @returns {number} - Returns the size for the preview items.
 */
export function getDocumentListSize() {
    return documentListSize;
}

// default values for all properties
export const theme = {
    LIGHT: "false",
    DARK: "true"
}

// default values for all properties
export const language = {
    EN: "en",
    DE: "de"
}

// default values for all properties
export const documentListSizes = {
    MIN: 100,
    SMALL: 200,
    MEDIUM: 300,
    LARGE: 400,
    MAX: 500
}

// default values for all properties
let defaultValues = {
    theme: theme.DARK,
    language: language.DE,
    documentListSize: documentListSizes.SMALL
}

// default values for all properties
export function getDefaultValues() {
    return defaultValues;
}

/**
 * This function sets the default values.
 * @param data {Object} - The default values.
 */
export function setDefaultValues(data) {
    defaultValues = data;
}

/**
 * This function sets the default values for the theme.
 * @param data {string} - The theme to be set.
 */
export function setDefaultValuesTheme(data) {
    defaultValues.theme = data;
}

/**
 * This function sets the default values for the language.
 * @param data {string} - The language to be set.
 */
export function setDefaultValuesLanguage(data) {
    defaultValues.language = data;
}

/**
 * This function sets the default values for the document list size.
 * @param data {number} - The document list size to be set.
 */
export function setDefaultValuesDocumentListSize(data) {
    defaultValues.documentListSize = data;
}

// default values for all properties
export const microphoneTimeout = 30000;

// default values for all properties
export const fuzzyStringThreshold = 0.5;