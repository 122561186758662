import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import HomePage from "./Homepage";
import Bye from "./routes/Bye";
import { OidcSecure } from "@axa-fr/react-oidc";
import CommentsPage from "./routes/CommentsPage";
import NewHomePage from "./NewHomePage";

// Define the routes of the application
export const router = createBrowserRouter([
    { path: "/", element: <App><NewHomePage /></App> },
    { path: "/fa/:faid", element: <App><NewHomePage /></App> },
    { path: "/bye", element: <Bye /> },
    { path: "/old", element: <OidcSecure><HomePage /></OidcSecure> },
    { path: "/old/fa/:faid", element: <OidcSecure><HomePage /></OidcSecure> },
    { path: "/document/:docid/comments", element: <App><CommentsPage /></App> }
])