import * as util from "../modules/util";
import "../styles/Rest/Homepage.css";
import * as constVariable from "../modules/constVariable.js";
import {useEffect, useState} from "react";
import { useNavigate } from "react-router";
import * as documentManager from "../modules/documentManager";

/** 
 * This function returns the document tile.
 * @param item - The document item.
 * @param setPdfpath - The function to set the PDF path.
 * @param setpdfID - The function to set the PDF ID.
 * @returns The document tile.
 */
const DocTile = ({ item, setPdfpath, setpdfID}) => {
    // save the image URL
    const [ImgURL, setImgURL] = useState(null);
    // get the navigation hook
    const navigate = useNavigate();

    // This function gets the preview image from the backend and sets it as the image source and saves it in the item for later use (cache).
    useEffect(() => {
        const abcd = async () => {
            if (item.preview.src.length === 0) {
                documentManager.getPreviewfromBackend(item.id, 0).then((data) => {
                    setImgURL(data);
                    item.preview = document.createElement("img");
                    item.preview.src = data;
                });
            } else {
                setImgURL(item.preview.src);
            }
        };
        abcd();
    }, [item]);

    // This function gets the PDF path from the backend and sets it as the PDF source.
    async function getPDFString() {                
        setPdfpath(null);
        documentManager.getPDFPath(item.id, (abc)=>{setPdfpath(abc)});
        setpdfID(item.id);
    }

    // This function returns the document tile.
    return (
        <div className="doc-tile doc-border">
            <div className="doc-tile-img"
                 style={{height: constVariable.getDocumentListSize() + "px"}}
            >
                <img src={ImgURL} alt={util.translate("nif")} data-bs-toggle="modal" data-bs-target="#popupPDF" className="doc-img" 
                    onClick={getPDFString}
                />
            </div>
            <div className="doc-tile-metadata">
                <div className="doc-tile-name doc-meta-text text-break" title={item.name}>
                    {item.name}
                </div>
                {/* add additional metadata here */}
            </div>
            <div className="doc-tile-actions">
                <button className="doc-border" onClick={() =>
                    documentManager.openPrintWindow(item.id)
                }>{util.translate("print")}</button> {/* TODO replace with print button */}
                {/* add actions here */}
                <button className="doc-border" onClick={() => navigate("/document/"+item.id+"/comments")}>
                    {util.translate("comments")}
                </button>
            </div>
        </div>
    );
}

export default DocTile;