import * as apiCalls from "./apiCalls.js";

/**
 * This function gets the preview image for the given document ID and page number.
 * @param {number} docid - The document ID for which the preview image is to be fetched.
 * @returns {Promise<string>} - A promise that resolves with the base64 URL of the preview image.
 */
export async function getRecentSearches(setSearchHist){
    apiCalls.getRecentSearches(setSearchHist);
}

/**
 * This function gets the preview image for the given document ID and page number.
 * @param {number} docid - The document ID for which the preview image is to be fetched.
 * @returns {Promise<string>} - A promise that resolves with the base64 URL of the preview image.
 */
export async function listFAs(faId, setListFAs){
    apiCalls.listFAs(faId, setListFAs);
}

