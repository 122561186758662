import { useEffect, useState } from "react";
import Comment from "./Comment";
import CommentForm from "./CommentForm";
import { useOidcUser } from "@axa-fr/react-oidc";
import { useTranslation } from "react-i18next";
import * as commentManager from "../modules/commentManager";
/**
// Comments component
// @param docid - The id of the document
// @returns The Comments component
*/
const Comments = ({ docid }) => {

    //Constants declaration
    const { oidcUser } = useOidcUser();
    const { t } = useTranslation();
    const [comments, setComments] = useState([]);
    const [activeComment, setActiveComment] = useState(null);
    const [pdfPath, setPdfPath] = useState("");
    const [views, setViews] = useState(0);
    const rootComments = comments.filter(comment => comment.referenceid === null);

    // Get the children of a comment
    // @param comment - The comment
    const getChildren = (comment) => {
        return comments.filter(c => c.referenceid === comment.id);
    }

    // Get the comments from comments Manager
    useEffect(() => {
        commentManager.getComments(docid, setComments);
    }, [docid]);

    // Get the views from comments Manager
    useEffect(() => {
        commentManager.getViews(docid, setViews);
    }, [docid]);


    // Display the document and comments
    useEffect(() => {
        commentManager.displayDocumentAndComments(docid, setPdfPath);
    }, [docid])

    // Add a comment
    // @param text - The text of the comment
    // @param parentId - The id of the parent comment
    const addComment = (text, parentId) => {
        commentManager.addComment(text, parentId, docid, setActiveComment, setComments, comments);
    };

    // Delete the comment
    // @param id - The id of the comment
    const deleteComment = (id) => {
        commentManager.deleteComment(docid, id, comments, setComments);
    };

    // Update the comment
    // @param text - The new text of the comment
    // @param id - The id of the comment
    const updateComment = (text, id) => {
        commentManager.updateComment(text, docid, id, comments, setActiveComment, setComments);
    }

    // Return the comments and the document in a container
    return (
        <div className="container py-5 h-75 mh-75">
            <div className="row d-flex justify-content-center h-100 mh-100">
                <div className="col-lg-6 col-xl-6 h-100 mh-100">
                    <object type="application/pdf" data={pdfPath} width="100%" height="100%" />
                </div>
                <div className="col-lg-6 col-xl-6 mh-100 overflow-y-scroll">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col">
                                    {rootComments.map(comment => (
                                        <div className="d-flex flex-start p-2" key={comment.id} >
                                            <Comment comment={comment} children={getChildren(comment)} addComment={addComment} uid={oidcUser?.sub} updateComment={updateComment} deleteComment={deleteComment} activeComment={activeComment} setActiveComment={setActiveComment} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <CommentForm handleSubmit={addComment} label={t("comment_add")} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5 text-body">{views + " " + t("views")}</div>
        </div>
    );
};

export default Comments;