// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FA-line {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.FA-list {
    width: auto;
    overflow-y: auto;
}

.FA-item {
    border: var(--border-thickness) solid var(--btn-border);
    padding: 3px;
}

.FA-item-fa {
    width: -moz-fit-content;
    width: fit-content;
}

.FA-item-link {
    text-decoration: none;
    color: var(--text-color);
}

.FA-c1 {
    width: 95px;
}

.FA-c2 {
    width: 110px;
}

.FA-c3 {
    width: 57px;
}

.s-FA-t {
    height: auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/NewHomepage/SearchFA.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,uDAAuD;IACvD,YAAY;AAChB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".FA-line {\r\n    display: flex;\r\n    justify-content: center;\r\n    margin: 0;\r\n    padding: 0;\r\n}\r\n\r\n.FA-list {\r\n    width: auto;\r\n    overflow-y: auto;\r\n}\r\n\r\n.FA-item {\r\n    border: var(--border-thickness) solid var(--btn-border);\r\n    padding: 3px;\r\n}\r\n\r\n.FA-item-fa {\r\n    width: fit-content;\r\n}\r\n\r\n.FA-item-link {\r\n    text-decoration: none;\r\n    color: var(--text-color);\r\n}\r\n\r\n.FA-c1 {\r\n    width: 95px;\r\n}\r\n\r\n.FA-c2 {\r\n    width: 110px;\r\n}\r\n\r\n.FA-c3 {\r\n    width: 57px;\r\n}\r\n\r\n.s-FA-t {\r\n    height: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
