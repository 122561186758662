import React, { useEffect } from 'react';
import { useParams } from "react-router";
import Avatar from 'react-avatar';
import bootstrap from "bootstrap/dist/js/bootstrap.js";
import bootbox from "bootbox";
import { useOidc, useOidcUser } from "@axa-fr/react-oidc";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { getColorByHash } from '../modules/util';
import shareDialog from '../modules/shareDialog';
import * as Switch from '@radix-ui/react-switch';
import * as constVariable from '../modules/constVariable'
import "../styles/Rest/Homepage.css";
import * as adminManager from "../modules/adminManager";

/**
 * UserDropdown component
 * @param isLightMode - The current theme of the application.
 * @param handleThemeToggle - The function to toggle the theme of the application.
 * @returns The UserDropdown component
 */
const UserDropdown = ({ isLightMode, handleThemeToggle }) => {
    
    // Constants declaration
    const { t } = useTranslation();
    // for OIDC
    const { logout } = useOidc();
    const { oidcUser } = useOidcUser();
    const { faid } = useParams();
    let helpDocument = "/assets/documentation/help_de.pdf";

    /**
     * This function opens the share dialog with the current FA number.
     * @param e - The event object.
     */
    function openShareDialog(e) {
        // get the current FA number
        let FA = document.querySelector(".current-FA").innerHTML;

        // remove old dialog if it exists
        let oldDialog = document.querySelector(".dialog");
        if (oldDialog) {
            oldDialog.remove();
        }

        // create a new dialog
        let dialog = document.createElement("dialog");

        // add the dialog class
        dialog.classList.add("dialog");
        dialog.classList.add(
            isLightMode ? "dialog-dark-mode" : "dialog-light-mode"
        );

        dialog.innerHTML = shareDialog(FA, isLightMode);

        // add the dialog to the body
        document.body.appendChild(dialog);

        // create QR code
        var QRCode = require("qrcode");
        var canvas = document.getElementById("canvas");
        QRCode.toCanvas(canvas,
            "https://dev.dokview.com/fa/" + FA,
            function (error) {
                if (error) console.error(error);
            }
        );

        // add event listener to close the dialog
        dialog.addEventListener("click", (e) => {
            if (e.target.tagName === "DIALOG") {
                dialog.close();
            }
        });

        // show the dialog
        dialog.showModal();

        // add popover functionality to the copy link button
        const popoverElement = document.querySelector('#share-copy');
        const popover = new bootstrap.Popover(popoverElement, {container: '.dialog', delay: {show: 100, hide: 100}, trigger: "manual"});

        // event listener to show the popover
        popoverElement.addEventListener('click', (e) => {
            popover.show();
        });

        // event listener to close the popover after 1.5 seconds
        popoverElement.addEventListener('shown.bs.popover', (e) => {
            setTimeout(() => {
                popover.hide();
            }, 1500);
        });
    }

    const delPref = () => {
        // Clear all cookies
        document.cookie.split(";").forEach((c) => {
            document.cookie = c.replace(/^ +/, "")
                .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });
        // Optionally, you can also reload the page after deleting cookies
        window.location.reload();
    };

    // Set the help document according to the language
    useEffect(() => {
        i18next.language == "de" ? helpDocument = "/assets/documentation/help_de.pdf" : helpDocument = "/assets/documentation/help_de.pdf";
    }, [i18next.language]);

    // Return the UserDropdown component
    return (
        // Avatar
        <div className="dropdown-center options-dropdown menu-bar-content" data-bs-theme={!isLightMode ? "light" : "dark"}>
            <button className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
            >
            <Avatar color={getColorByHash(oidcUser && (oidcUser.name))}
                name={oidcUser && (oidcUser.name)}
                size="40"
                round={true} />
        </button>
        <ul className="dropdown-menu" style={{overflow: 'visible', cursor: 'pointer'}}>
            {/* dark and light theme switch */}
            <li>
                <div className="dropdown-item" style={{display: "flex", alignItems: "center"}} onClick={handleThemeToggle}>
                    {/* Dark and Light Theme switch */}
                        <label className="themeLabel"
                            htmlFor="theme-switch"
                            style={{ paddingRight: 15 }}
                            onClick={(e) => e.stopPropagation()}>
                            {t("Theme")}
                        </label>
                        <Switch.Root
                            className="SwitchRoot"
                            id="theme-switch"
                            checked={isLightMode}
                        >
                            <Switch.Thumb className="SwitchThumb" />
                        </Switch.Root>
                </div>
            </li>
            {/* share button */}
            {faid && (
                <li>
                    <div className="dropdown-item"
                        onClick={openShareDialog}
                        title={t("share")}>
                        <img src={process.env.PUBLIC_URL +
                            "/assets/icons/share.svg"}
                            alt="Share"
                        />
                        {t("share")}
                    </div>
                </li>
            )}
            {/* language selection */}
            <li className="dropstart">
                <div className="dropdown-item btn-group" data-bs-theme={!isLightMode ? "light" : "dark"} data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="inside">
                    <button type="button" className="dropdown-toggle">{t("lang-sel")}</button>
                    <ul className="dropdown-menu">
                        <li className="dropdown-item" onClick={() => i18next.changeLanguage("de")}><span className="flag-icon flag-icon-de icon"></span><a>{t("german")}</a></li>
                        <li className="dropdown-item" onClick={() => i18next.changeLanguage("en")}><span className="flag-icon flag-icon-gb icon"></span><a>{t("english")}</a></li>
                    </ul>
                </div>
            </li>
            <li>
                {adminManager.getIsAdmin() && (
                    <a className="dropdown-item admin-properties-button" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={
                        () => {
                            let theme = document.getElementById("adminTheme")
                            let language = document.getElementById("adminLanguage")
                            let size = document.getElementById("adminSize")
                            if (theme && language && size) {
                                adminManager.getDefaultValues()

                                let defaultValues = constVariable.getDefaultValues()
                                theme.value = defaultValues.theme
                                language.value = defaultValues.language
                                size.value = defaultValues.documentListSize
                            }
                        }
                    }>
                        {t("AdminProperties")}
                    </a>
                )}
            </li>
            {/*Delete Preferences BTN*/}
            <li>
                <a className={"dropdown-item"} onClick={delPref}>
                    {t("delPref")}
                </a>
            </li>
            {/* Help button opens help.pdf as new window */}
            <li>
                <a className="dropdown  dropdown-item" href={process.env.PUBLIC_URL + t("helpPDF")} target="_blank" rel="noreferrer">
                    {t("help")}
                </a>
            </li>
            {/* logout button */}
            <li>
                <a className="dropdown-item logout-background"
                   onClick={() => logout("/bye")}>
                    {t("logout")}
                </a>
            </li>
        </ul>
        {/* Modal div for Admin User Option*/}
        <div className="modal fade setThemeModeText" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">{t("DefaultPropeties")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {/* Admin Properties -*/}
                    <div className="modal-body docBox adminProperties">
                        <div className="adminPropertiesDiv">
                            <label>
                                {t("Theme")}:
                            </label>
                            <select className="form-select form-select mb-3 adminOptionButton" aria-label=".form-select-lg example" id="adminTheme">
                                <option value="false">{t("light")}</option>
                                <option value="true">{t("dark")}</option>
                            </select>
                        </div>
                        <div className="adminPropertiesDiv">
                            <label>
                                {t("language")}:
                            </label>
                            <select className="form-select form-select mb-3 adminOptionButton" aria-label=".form-select-sm example" id="adminLanguage">
                                <option value="de">{t("german")}</option>
                                <option value="en">{t("english")}</option>
                            </select>
                        </div>
                        <div className="adminPropertiesDiv">
                            <label>
                                {t("TilesSize")}:
                            </label>
                            <select className="form-select form-select mb-3 adminOptionButton" id="adminSize" aria-label=".form-select-sm example">
                                <option value="100">{t("min")}</option>
                                <option value="200">{t("small")}</option>
                                <option value="300">{t("medium")}</option>
                                <option value="400">{t("large")}</option>
                                <option value="500">{t("max")}</option>
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t("Close")}</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={ () =>{
                            let size = document.getElementById("adminSize").value
                            let language = document.getElementById("adminLanguage").value
                            let theme = document.getElementById("adminTheme").value

                            adminManager.setDefaultValues(theme, language, size)
                        }}>
                            {t("SaveChanges")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default UserDropdown;