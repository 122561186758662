// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recent-line {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.recent-list {
    width: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

@media (max-width: 900px){
    .recent-item {
        font-size: 0.84em;
    }
    .recent-c1 {
        width: 80px !important;
    }

    .recent-c2 {
        width: 92px !important;
    }

    .recent-c3 {
        width: 50px !important;
    }
}

@media (max-width: 780px){
    .recent-item {
        font-size: 0.65em;
    }
    .recent-c1 {
        width: 64px !important;
    }

    .recent-c2 {
        width: 75px !important;
    }

    .recent-c3 {
        width: 40px !important;
    }
}

.recent-item {
    border: var(--border-thickness) solid var(--btn-border);
    padding: 3px;
}

.recent-item-fa {
    width: -moz-fit-content;
    width: fit-content;
}

.recent-item-link {
    text-decoration: none;
    color: var(--text-color);
}

.recent-c1 {
    width: 95px;
}

.recent-c2 {
    width: 110px;
}

.recent-c3 {
    width: 57px;
}`, "",{"version":3,"sources":["webpack://./src/styles/NewHomepage/RecentSearches.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,sBAAsB;IAC1B;;IAEA;QACI,sBAAsB;IAC1B;;IAEA;QACI,sBAAsB;IAC1B;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,sBAAsB;IAC1B;;IAEA;QACI,sBAAsB;IAC1B;;IAEA;QACI,sBAAsB;IAC1B;AACJ;;AAEA;IACI,uDAAuD;IACvD,YAAY;AAChB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".recent-line {\r\n    display: flex;\r\n    justify-content: center;\r\n    margin: 0;\r\n    padding: 0;\r\n}\r\n\r\n.recent-list {\r\n    width: auto;\r\n    overflow-y: auto;\r\n    overflow-x: hidden;\r\n}\r\n\r\n@media (max-width: 900px){\r\n    .recent-item {\r\n        font-size: 0.84em;\r\n    }\r\n    .recent-c1 {\r\n        width: 80px !important;\r\n    }\r\n\r\n    .recent-c2 {\r\n        width: 92px !important;\r\n    }\r\n\r\n    .recent-c3 {\r\n        width: 50px !important;\r\n    }\r\n}\r\n\r\n@media (max-width: 780px){\r\n    .recent-item {\r\n        font-size: 0.65em;\r\n    }\r\n    .recent-c1 {\r\n        width: 64px !important;\r\n    }\r\n\r\n    .recent-c2 {\r\n        width: 75px !important;\r\n    }\r\n\r\n    .recent-c3 {\r\n        width: 40px !important;\r\n    }\r\n}\r\n\r\n.recent-item {\r\n    border: var(--border-thickness) solid var(--btn-border);\r\n    padding: 3px;\r\n}\r\n\r\n.recent-item-fa {\r\n    width: fit-content;\r\n}\r\n\r\n.recent-item-link {\r\n    text-decoration: none;\r\n    color: var(--text-color);\r\n}\r\n\r\n.recent-c1 {\r\n    width: 95px;\r\n}\r\n\r\n.recent-c2 {\r\n    width: 110px;\r\n}\r\n\r\n.recent-c3 {\r\n    width: 57px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
