import React from "react";
import UserDropdown from "./UserDropdown";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";

/**
 * MenuBar component
 * @param isLightMode - The light mode status
 * @param handleThemeToggle - The theme toggle function
 * @returns The MenuBar component
 */
const MenuBar = ({ isLightMode, handleThemeToggle}) => {
    // Constants declaration
    const { t } = useTranslation();

    // Return the MenuBar component
    return (
        <div className="menu-bar">
            <div className="logo-container">
                <Link to="/">
                    <img src={process.env.PUBLIC_URL + "/assets/logo/logo.svg"}
                         alt="Logo"
                         className="logo-image"
                    />
                </Link>
            </div>
            <div className="nav-bar">
                <button className="btn btn-secondary" data-bs-toggle="offcanvas" href="#offcanvasExample"
                        aria-controls="offcanvasExample">
                    {t("favourite")}
                </button>
                <UserDropdown isLightMode={isLightMode} handleThemeToggle={handleThemeToggle}/>
            </div>
        </div>
    );
};

export default MenuBar;