// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.light-mode{
 /*   background-color: #d5d5d5;*/
}

.search-docs-container {
    height: 100%;
    padding: 5px;
}


.doc-list-container {
    overflow-y: auto;
    overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/styles/NewHomepage/SearchDocs.css"],"names":[],"mappings":"AAAA;CACC,gCAAgC;AACjC;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;;AAGA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".light-mode{\r\n /*   background-color: #d5d5d5;*/\r\n}\r\n\r\n.search-docs-container {\r\n    height: 100%;\r\n    padding: 5px;\r\n}\r\n\r\n\r\n.doc-list-container {\r\n    overflow-y: auto;\r\n    overflow-x: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
