import { useParams } from "react-router";
import Comments from "../components/Comments";
import '../styles/NewHomepage/CommentsPage.css';
import '../styles/Rest/Homepage.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * CommentsPage component
 * @param {Object} props - The props of the CommentsPage component
 * @param {boolean} props.isLightMode - The light mode status
 * @returns {JSX.Element} CommentsPage component
 */
const CommentsPage = ({ isLightMode }) => {
    // get the document ID from the URL
    const { docid } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    // return the JSX for the CommentsPage component
    return (
        <>
            <div className={`h-100 bodyBackground ${!isLightMode ? "light-mode" : "dark-mode"}`} data-bs-theme={!isLightMode ? "light" : "dark"}>
                <h1 className="text-body">{t("comments_page")}</h1>
                <div className="position-absolute top-0 start-0 p-4 text-body" onClick={() => navigate(-1)}>&lt;-- {t("back")}</div>
                <Comments docid={docid} />
            </div>
            {/* Toast container */}
            <div aria-live="polite" aria-atomic="true" className="position-relative">
                <div className="toast-container position-fixed top-0 end-0 p-3"></div>
            </div>
        </>
    );
};

export default CommentsPage;