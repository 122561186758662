import React from "react";
import { OidcSecure } from "@axa-fr/react-oidc";
import { useTheme } from "./modules/theme";

/**
 * This function generates the main App component.
 * @param {Object} children - The children of the App component.
 * @returns {Element} - Returns the App component.
 */
function App({ children }) {
    // Dark and Light Theme switch
    const { isLightMode, setIsLightMode, handleThemeToggle } = useTheme();

    /**
     * This function renders the children of the App component.
     * @returns {Element} - Returns the children of the App component.
     */
    const renderChildren = () => {
        return React.Children.map(children, el => {
            return React.cloneElement(el, { isLightMode: isLightMode, setIsLightMode: setIsLightMode, handleThemeToggle: handleThemeToggle });
        })
    }

    // return the JSX for the App component
    return (
        <>
            <OidcSecure>
                {renderChildren()}
            </OidcSecure>
        </>

    );
}

export default App;
