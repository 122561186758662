import React from 'react';
import FaSearchBar from "../components/FaSearchBar";
import { useTranslation } from "react-i18next";

/**
 * This component represents the search FA component.
 * @param {string} FA_ID_maxInput - The FA ID max input.
 * @param {string} faid - The FA ID.
 * @param {boolean} isLightMode - The light mode.
 * @param {boolean} isListening - The listening state.
 * @param {function} setIsListening - The function to set the listening state.
 * @param {boolean} showRecentSearches - The recent searches state.
 * @param {function} setShowRecentSearches - The function to set the recent searches state.
 * @param {object} searchRef - The search reference.
 * @param {Array} recentSearches - The recent searches.
 * @param {function} setRecentSearches - The function to set the recent searches.
 * @returns {JSX.Element} - The search FA component.
 */
const SearchFA = ({ FA_ID_maxInput, faid, isLightMode, isListening, setIsListening, showRecentSearches,
    setShowRecentSearches, searchRef, recentSearches, setRecentSearches }) => {

    // added for translation
    const { t } = useTranslation();

    // return the search FA component
    return (
        <div className="box-top-left"
            style={{height: "100%"}}>
            <h2 className="s-FA-t">{t("searchFA")}</h2>
            <FaSearchBar
                FA_ID_maxInput={FA_ID_maxInput}
                isLightMode={isLightMode}
                faid={faid} isListening={isListening}
                setIsListening={setIsListening}
                showRecentSearches={showRecentSearches}
                setShowRecentSearches={setShowRecentSearches}
                searchRef={searchRef}
                recentSearches={recentSearches}
                setRecentSearches={setRecentSearches} />
        </div>
    );
}

export default SearchFA;