import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import * as fav from "../modules/favouriteManager";

/**
 * This component is used to display the save text for the FA number.
 * @returns The SaveText component
 */

const SaveText = ({  }) => {
    // Constants declaration
    const [isFavourite, setIsFavourite] = useState(null);
    // for dynamic routing (URL)
    const { faid } = useParams();
    const { t } = useTranslation();

    /**
     * This function gets the favourite FA numbers from the backend and sets the SaveText status at the load of the page.
     */
    useEffect(() => {
        if (!faid) return;

        fav.getFavouriteForFavFAIcon().then(tmp => {
            const isFav = tmp.some(el => el == faid);
            setIsFavourite(isFav);
        }).catch(error => {
            console.error("Error fetching favourite data:", error);
        });
    }, [faid]);

    // Return the SaveText component
    return (
        <>
            {faid && isFavourite !== null && (
                isFavourite ? 
                    <>| <a href='#' onClick={(e) => {e.preventDefault(); fav.handleFAFavIconClick(setIsFavourite);}} onMouseOver={(e) => e.target.innerHTML=t("saved_fa_remove")} onMouseOut={(e) => e.target.innerHTML=t("saved_fa")}>{t("saved_fa")}</a></>
                    :
                    <>| <a href="#" onClick={(e) => {e.preventDefault(); fav.handleFAFavIconClick(setIsFavourite);}}>{t("unsaved_fa")}</a></>
                )
            }
        </>
    );
}

export default SaveText;