import * as apiCalls from "./apiCalls.js";
import * as util from "./util.js";

// callback function for getComments
export async function getComments(faid, setComments){
    apiCalls.getComments(faid, setComments);
}

// callback function for getViews
export async function getViews(faid, setViews){
    apiCalls.getViews(faid, setViews);
}

// callback function for getFavourite
const displayDocumentAndCommentsCallback = (response, setPdfPath) => {
    // create a Blob from the PDF Stream
    const file = new Blob([response.data], {
        type: "application/pdf",
    });
    // build a URL from the file
    const fileURL = URL.createObjectURL(file);
    setPdfPath(fileURL);
}

/**
 * Displays the document and comments of a FA
 * @param {number} faid - FA number
 * @param {function} setPdfPath - function to set the pdf path
 */
export async function displayDocumentAndComments(faid, setPdfPath){
    apiCalls.getPDFDocumentForComments(faid, displayDocumentAndCommentsCallback, setPdfPath);
}

// callback function for addComment
export const addComment = (text, parentId, faid, setActiveComment, setComments, comments) => {
    apiCalls.addComment(text, parentId, faid, setActiveComment, setComments, comments);
}

// callback function for deleteComment
export const deleteComment = (faid, id, comment, setComments) => {
    if (window.confirm("Are you sure you want to delete this comment?") === false) return;
    apiCalls.deleteComment(faid, id, comment, setComments);
}

// callback function for updateComment
export const updateComment = (text, faid, id, comments, setActiveComment, setComments) => {
    apiCalls.updateComment(text, faid, id, comments, setActiveComment, setComments);
}