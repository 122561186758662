import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import '../styles/NewHomepage/SearchFA.css';
import * as util from "../modules/util.js";
import * as faManager from "../modules/faManager.js";

/**
 * ListFAs component.
 * @param {string} faId - The FA ID.
 * @returns {JSX.Element}
 */
const ListFAs = ({faId}) => {
    // List of FAs
    const [listFAs, setListFAs] = useState([]);
    const {t} = useTranslation();

    // Load the list of FAs
    useEffect(() => {
        if (faId === undefined || faId === null) {
            setListFAs([]);
            return;
        }
        faManager.listFAs(faId, setListFAs);
    }, [faId])

    // Render the list of FAs
    return (
        <div>
            {listFAs.length > 0 ? <div>
                <div className="FA-line FA-top-line">
                    <div className="FA-item FA-c1">
                        {util.translate("FA number")}
                    </div>
                    <div className="FA-item FA-c2">
                        {util.translate("status")}
                    </div>
                    <div className="FA-item FA-c3"></div>
                    {/* NEEDED */}
                </div>
                {listFAs.map((item) => {
                    return (
                        <div key={item} className="FA-line">
                            <Link to={"/fa/" + item} className="FA-item-link FA-item FA-c1">
                                {item}
                            </Link>
                            <div
                                className="FA-item-delete FA-item FA-c2"> {/* TODO: fill with actual status */}
                                dummy status
                            </div>
                            <Link to={"/fa/" + item} className="FA-item-link FA-item FA-c3">
                                {util.translate("open")}
                            </Link>
                        </div>
                    )
                })}
            </div> : (
                <div>
                    {t("noFAsFound")}
                </div>
            )}
        </div>
    );
}

export default ListFAs;