import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import '../styles/NewHomepage/RecentSearches.css';
import * as util from "../modules/util.js";
import * as faManager from "../modules/faManager.js";

/**
 * This component represents the recent searches.
 * @returns {JSX.Element} - The recent searches.
 */
const RecentSearches = () => {

    // Constants declaration
    const [ searchHist, setSearchHist ] = useState([]);
    const[ recentListHeight, setRecentListHeight ] = useState(0);
    const { t } = useTranslation();

    // Load the recent searches
    useEffect(() => {
        faManager.getRecentSearches(setSearchHist);
    }, [])

    // Calculate the height of the recent searches list
    useEffect(() => {
        const handleResize = () => {
            // Check if the elements exist in the DOM
            const trElement = document.querySelector('.box-top-right');
            const rstextElement = document.querySelector('.rstext');
            const rlElement = document.querySelector('.recent-list');

            // Check if the elements exist in the DOM
            if (!trElement || !rstextElement || !rlElement) {
                console.log("One or more elements not found.");
                return;
            }

            // Calculate the new height for recent-list
            const trHeight = trElement.offsetHeight;
            const rstextHeight = rstextElement.offsetHeight;
            const rstextMargin = parseInt(window.getComputedStyle(rstextElement).marginBottom);
            const rlborderT = parseInt(window.getComputedStyle(rlElement).borderTopWidth);
            const rlborderB = parseInt(window.getComputedStyle(rlElement).borderBottomWidth);

            // Set the new height for recent-list
            const newRecentListHeight = trHeight - rstextHeight - rstextMargin - rlborderT - rlborderB;
            setRecentListHeight(newRecentListHeight);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Call handleResize initially
        handleResize();

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Return the recent searches
    return (
        <div className="box-top-right"
            style={{height: "100%"}}>
            <div>
                <h2 className="rstext">{t("recentSearch")}</h2>
            </div>
            <div className='docBox recent-list'
                style={{maxHeight: `${recentListHeight}px`}}>
                {searchHist.length > 0 ? <div className="recent-line">
                        <div className="recent-item recent-c1">
                            {util.translate("FA number")}
                        </div>
                        <div className="recent-item recent-c2">
                            {util.translate("status")}
                        </div>
                        <div className="recent-item recent-c3"></div>
                        {/* NEEDED */}
                    </div> :
                    <div>
                        {t("noFAsFound")}
                    </div>}
                {searchHist && searchHist.map((item) => {
                    return (
                        <div key={item} className="recent-line">
                            <Link to={"/fa/" + item} className="recent-item-link recent-item recent-c1">
                                {item}
                            </Link>
                            <div className="recent-item-delete recent-item recent-c2"> {/* TODO fill with actual status */}
                                dummy status
                            </div>
                            <Link to={"/fa/" + item} className="recent-item-link recent-item recent-c3">
                                {util.translate("open")}
                            </Link>
                        </div>

                    )
                })}
            </div>
        </div>
    );
}

export default RecentSearches;