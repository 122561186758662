import { useState } from "react";
import { useTranslation } from "react-i18next";

/**
// CommentForm component
// @param handleSubmit - The function to call when submitting the form
// @param label - The label of the submit button
// @param hasCancelButton - The boolean to show the cancel button
// @param handleCancel - The function to call when clicking the cancel button
// @param initialText - The initial text of the textarea
// @returns The CommentForm component
*/
const CommentForm = ({ handleSubmit, label, hasCancelButton = false, handleCancel, initialText = "" }) => {

    // Constants declaration
    const { t } = useTranslation();
    const [text, setText] = useState(initialText);
    const isTextAreaDisabled = text.trim().length === 0;
    
    // Submit the form and reset the text
    // @param e - The event object
    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit(text.trim());
        setText("");
    }

    // Return the form with the textarea, the submit button and the cancel button
    return (
        <form onSubmit={onSubmit}>
            <textarea className="form-control" value={text} onChange={(e) => setText(e.target.value)} />
            <button className="btn btn-primary m-2 align-left" type="submit" disabled={isTextAreaDisabled}>{label}</button>
            {hasCancelButton && <button className="btn btn-primary m-2 align-left" type="button" onClick={handleCancel}>{t("cancel")}</button>}
        </form>
    );
};

export default CommentForm;