import axios from "axios";
import * as constVariable from "./constVariable.js";
import * as util from "./util.js";

/**
 * This function gets the PDF from the backend for the given document id and opens it in a new window.
 * @param {number} docid - The document id for which the PDF is to be fetched.
 * @returns {Promise<void>} - A promise that resolves when the PDF is opened in a new window.
 */
export async function getPDFDocument(docid, callback) {
    try {
        await axios
            .get(constVariable.baseurl + "/document/" + docid, { responseType: "blob"})
            .then((response) => {
                callback(response);
            })
    } catch (error) {
        return { error };
    }
}

/**
 * This function gets the PDF from the backend for the given document id and opens it in a new window.
 * @param {number} docid - The document id for which the PDF is to be fetched.
 * @returns {Promise<void>} - A promise that resolves when the PDF is opened in a new window.
 * @param {Function} callback - The callback function to be called after the PDF is fetched.
 * @param {Function} setPdfPath - The callback function to be called after the PDF path is set.
*/
export async function getPDFDocumentForComments(docid, callback, setPdfPath) {
    try {
        await axios
            .get(constVariable.baseurl + "/document/" + docid, { responseType: "blob"})
            .then((response) => {
                callback(response, setPdfPath);
            })
    } catch (error) {
        util.showToast("An error occurred while fetching the PDF.", util.ToastType.ERROR);
        return error;
    }
}

/**
 * This function gets the documents from the backend for the given FA number.
 * @param {number} FAnumber - The FA number for which the documents are to be fetched.
 * @returns {Promise<void>} - A promise that resolves when the documents are fetched.
 */
export async function getDocumentsFromFA(FAnumber) {
    return await axios.get(constVariable.baseurl + "/fa/" + FAnumber, {
        responseType: "json", // axios uses 'json' responseType, and it automatically parses the JSON, so you might not need to parse it again.
    });
}

/**
 * This function gets the PDF from the backend for the given document id and opens it in a new window.
 * @param {number} docid - The document id for which the PDF is to be fetched.
 * @returns {Promise<void>} - A promise that resolves when the PDF is opened in a new window.
 */
export async function openPDFWindow(docid,pdfWindow, openPDFWindowCallback) {
    try {
        await axios
            .get(constVariable.baseurl + "/document/" + docid, { responseType: "blob"})
            .then((response) => {
                openPDFWindowCallback(response, pdfWindow);
            });
    } catch (error) {
        util.showToast("An error occurred while opening the PDF window.", util.ToastType.ERROR);
        return { error };
    }
}

/**
 * This function gets the preview image from the backend for the given document id.
 * @param {number} docid - The document id for which the preview image is to be fetched.
 * @returns {Promise<string | null>} - A promise that resolves with the Base64 URL of the preview image or null in case of error.
 */
export async function getPreviewfromBackend(docid, pageNumber) {
    return await axios.get(
        constVariable.baseurl + "/document/" + docid + "/preview",
        {
            responseType: "blob",
            params: {page: pageNumber}
        }
    );
}
/**
 * getDefaultValues function gets the default values for the app.
 * @param {Function} setDefaultValuesCallback - The callback function to be called after the default values are fetched.
 */
export async function getDefaultValues(setDefaultValuesCallback) {
    try {
        await axios
            .get(constVariable.baseurl + "/defaultValues", { responseType: "json"})
            .then((response) => {
                setDefaultValuesCallback(response);
            })
    } catch (error) {
        util.showToast("An error occurred while fetching the default values.", util.ToastType.ERROR);
        return { error };
    }
}

/**
 * This function sets the default values for the app.
 * @param {Object} datanew - The data object containing the default values.
 * @param {Object} config - The headers for the post request.
 */
export async function setDefaultValues(datanew, config){
    axios.post(constVariable.baseurl + "/defaultValues", datanew,config)
        .catch((error) => {
            util.showToast("An error occurred while setting the default values: " + error, util.ToastType.ERROR);
        });
}

/**
 * This function gets the documents for the given FA number and saves them in currentData.
 * @param {number} docid - The FA number for which the documents are to be fetched.
 * @returns {Promise<void>} - A promise that resolves when the documents are fetched.
 * @param {Function} pdfPathCallback - The callback function to be called after the documents are fetched.
 */
export async function getPDFPath(docid, abcd, pdfPathCallback) {
    try {
        await axios
            .get(constVariable.baseurl + "/document/" + docid, { responseType: "blob"})
            .then((response) => {
                pdfPathCallback(response, abcd);
            })
            .catch((error) => {
                util.showToast("An error occurred while fetching the PDF path: " + error, util.ToastType.ERROR);
            });
    } catch (error) {
        util.showToast("An error occurred while fetching the PDF path: " + error, util.ToastType.ERROR);
        return { error };
    }
}

/**
 * HTTP Post Method: Setting User's favourite document
 * @param {Number} faIdPostFunction
 */
export const postFavourite = async(faIdPostFunction) => {
    try {
        const datanew = {
            faid: parseInt(faIdPostFunction),
        };

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        axios.post(constVariable.baseurl + "/favourite", datanew,config).catch(error => {
            util.showToast("An error occurred while setting favourite.\n" + error, util.ToastType.ERROR);
        });
    } catch (error) {
        util.showToast("An error occurred while setting favourite.\n" + error, util.ToastType.ERROR);
        return {error};
    }
}

/**
 * HTTP Delete Method: Deleting User's favourite FA
 * @param {Number} faIdPostFunction
 */
export const deleteFavourite = async(faIdPostFunction) => {
    try {
        axios.delete(constVariable.baseurl + "/favourite/" + faIdPostFunction).then(response => {
        }).catch(error => {
            util.showToast("An error occurred while deleting favourite.\n" + error, util.ToastType.ERROR);
        });
    } catch (error) {
        util.showToast("An error occurred while deleting favourite.\n" + error, util.ToastType.ERROR);
        return {error};
    }
}

/**
 * This Function is a slimmer version of getFavourite. GetFavourite is in HomePage body.
 * @returns {Number[]} Returns an array with the favourite FA-Numbers.
 */
export async function getFavourite(){
    return await axios.get(constVariable.baseurl + "/favourite", {
        responseType: "text",
    });
}

/**
 * This function gets the comments from the backend for the given document id.
 * @param {number} docid - The document id for which the comments are to be fetched.
 * @returns {Promise<void>} - A promise that resolves when the comments are fetched.
 */
export async function getRecentSearches(setSearchHist){
    axios.get(constVariable.baseurl + "/fa/recent")
        .then((response) => {
            setSearchHist(response.data);
        });
}

/**
 * This function gets the comments from the backend for the given document id.
 * @param {number} docid - The document id for which the comments are to be fetched.
 * @returns {Promise<void>} - A promise that resolves when the comments are fetched.
 */
export async function listFAs(faId, setListFAs){
    axios.get(constVariable.baseurl + "/fa-list/" + faId)
    .then((response) => {
        setListFAs(response.data);
    });
}

/**
 * This function gets the comments from the backend for the given document id.
 * @param {number} docid - The document id for which the comments are to be fetched.
 * @returns {Promise<void>} - A promise that resolves when the comments are fetched.
 */
export async function getComments(faid, setComments){
    axios.get(constVariable.baseurl + "/document/" + faid + "/comments")
    .then(res => {
        setComments(res.data);
    })
}

/**
 * This function gets the views from the backend for the given document id.
 * @param {number} docid - The document id for which the views are to be fetched.
 * @returns {Promise<void>} - A promise that resolves when the views are fetched.
 */

export async function getViews(faid, setViews){
    axios.get(constVariable.baseurl + "/document/" + faid + "/views")
    .then(res => {
        setViews(res.data);
    })
}

/**
 * This function adds a comment to the backend.
 * @param {string} text - The text of the comment.
 * @param {number} parentId - The parent id of the comment.
 * @param {number} faid - The faid of the comment.
 * @param {Function} setActiveComment - The callback function to be called after the comment is added.
 * @param {Function} setComments - The callback function to be called after the comment is added.
 * @param {Object[]} comments - The comments to be updated.
 */
export async function addComment(text, parentId, faid, setActiveComment, setComments, comments) {
    axios.post(constVariable.baseurl + "/document/" + faid + "/comments", { comment: text, referenceid: parentId }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
    .then(res => {
        setActiveComment(null);
        setComments([...comments, res.data]);
    });
}

/**
 * This function deletes a comment from the backend.
 * @param {number} faid - The faid of the comment.
 * @param {number} id - The id of the comment.
 * @param {Object[]} comments - The comments to be updated.
 * @param {Function} setComments - The callback function to be called after the comment is deleted.
 */
export async function deleteComment(faid, id, comments, setComments) {
    axios.delete(constVariable.baseurl + "/document/" + faid + "/comments/" + id)
    .then(() => {
        const updatedComments = comments.filter(comment => comment.id !== id);
        setComments(updatedComments);
    });
}

/**
 * This function updates a comment in the backend.
 * @param {string} text - The text of the comment.
 * @param {number} faid - The faid of the comment.
 * @param {number} id - The id of the comment.
 * @param {Object[]} comments - The comments to be updated.
 * @param {Function} setActiveComment - The callback function to be called after the comment is updated.
 * @param {Function} setComments - The callback function to be called after the comment is updated.
 */
export async function updateComment(text, faid, id, comments, setActiveComment, setComments){
    axios.patch(constVariable.baseurl + "/document/" + faid + "/comments/" + id, { comment: text }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
            .then(() => {
                const updatedComments = comments.map(comment => {
                    if (comment.id === id) {
                        comment.comment = text;
                    }
                    return comment;
                });
                setComments(updatedComments);
                setActiveComment(null);
            });
}