import React, {useState} from 'react';
import DocList from "../components/DocList";
import DocSearchBar from "../components/DocSearchBar";
import  "../styles/NewHomepage/SearchDocs.css"
import ShowFaStatus from "../components/ShowFaStatus";

/**
 * This component represents the document search page.
 * @param isLightMode - The light mode status.
 * @returns {JSX.Element} - The document search page.
 */
const SearchDocs = ({isLightMode}) => {

    // Search query
    const [searchQuery, setSearchQuery] = useState('');

    // Render the document search page
    return (
        <div className="search-docs-container">
            {/* Document search bar */}
            <DocSearchBar setSearchQuery={setSearchQuery} searchQuery={searchQuery}/>
            <ShowFaStatus/>
            <DocList isLightMode={isLightMode} searchQuery={searchQuery}/>
        </div>
    );
}

export default SearchDocs;