import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import * as constVariable from './constVariable';
import i18next from "i18next";
import * as adminManager from './adminManager';
import { useOidc } from '@axa-fr/react-oidc';

/**
 * This hook is used to manage the theme of the app.
 * It checks if the theme cookie exists and sets the theme based on it.
 * If the cookie does not exist, it sets the theme based on the default values.
 * It also provides a function to toggle the theme.
 * @returns {Object} - The theme state and the function to toggle the theme.
 */
export const useTheme = () => {
    // Set the default values for the app
    const [isLightMode, setIsLightMode] = useState(false);
    // Set the default values for the app
    useEffect(() => {
        fetchData();
    }, []);

    const { isAuthenticated } = useOidc();

    // Fetch the data
    async function fetchData() {
        // Set the default values for the app
        if (isAuthenticated) {
            await adminManager.getDefaultValues();
        }
       
        // Check if the theme cookie exists
        const savedTheme = Cookies.get("DarkTheme");
        // If the theme cookie exists, set the theme based on it
        if (savedTheme) {
            setIsLightMode(savedTheme === "true");
        }else{
            setIsLightMode(constVariable.getDefaultValues().theme === constVariable.theme.LIGHT ? false : true);
        }

        // set savedlanguage to cookie i18next
        const savedLanguage = Cookies.get("i18next");

        //If the language cookie exists, set the language based on it (dev is not defined)
        if(savedLanguage != "dev" && savedLanguage != undefined){
            i18next.changeLanguage(savedLanguage);
        }else{            
            i18next.changeLanguage(constVariable.getDefaultValues().language === constVariable.language.DE ? "de" : "en");
            // remove language cookie after setting language, because next time it should be get it again from default values
            Cookies.remove("i18next");
        }
    }

    // Toggle the theme
    const handleThemeToggle = () => {
        const newMode = !isLightMode;
        setIsLightMode(newMode);

        //set microphone icon image
        var imgageMic = document.getElementsByClassName("microphone-icon")[0];
        imgageMic.src = newMode ? process.env.PUBLIC_URL + "/assets/icons/mic_white.svg" : process.env.PUBLIC_URL + "/assets/icons/mic.svg";
    
        // Set the theme cookie with the new value
        Cookies.set("DarkTheme", newMode.toString(), { expires: 365 }); // Expires in 1 year
    };

    return { isLightMode, setIsLightMode, handleThemeToggle };
}