import React, { useState, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

//Saves the last language mode to prevent unnecessary re-rendering
let lastLanuageMode = 'de-DE';

/**
 * Dictaphone component
 * @param {string} languageMode - The language mode for the speech recognition
 * @param {function} onChange - The function to call when the speech recognition has a result
 * @param {function} onSearch - The function to call when the speech recognition should search
 * @param {boolean} isListening - The state of the speech recognition
 * @param {function} onListeningModeChange - The function to call when the speech recognition mode changes
 * @param {function} onResetValue - The function to call when the speech recognition should reset the value
 * @param {function} onDeleteChar - The function to call when the speech recognition should delete a character
 * @returns {null} - Returns null if the browser does not support speech recognition
 */
const Dictaphone = ({languageMode, onChange, onSearch, isListening, onListeningModeChange, onResetValue, onDeleteChar}) => {
    //Commands for the speech recognition
    const commands = [
        {
            command: '*reset*',
            callback: () => onResetValue(),
        },
        {
            command: '*zurücksetzen*',
            callback: () => onResetValue(),
        },
        {
            command: '*suche*',
            callback: () => onSearch(),
        },
        {
            command: '*search*',
            callback: () => onSearch(),
        },
        {
            command: '*los*',
            callback: () => onSearch(),
        },
        {
            command: '*go*',
            callback: () => onSearch(),
        },
        {
            command: '*lösch*',
            callback: () => onDeleteChar(),   
        },
        {
            command: '*delete*',
            callback: () => onDeleteChar(),   
        },
        {
            command: '*entferne*',
            callback: () => onDeleteChar(),   
        },
        {
            command: '*remove*',
            callback: () => onDeleteChar(),   
        },
        {
            command: '*drop table*',
            callback: () => dropTable()
        },
        {
            command: '*tabelle fallen lassen*',
            callback: () => dropTable()
        }
    ];
    
    //Speech recognition hooks and effects
    const {
        interimTranscript,
        finalTranscript,
        resetTranscript,
    } = useSpeechRecognition({commands});

    //Call the onChange function when the speech recognition has a result
    useEffect(() => {
        if(finalTranscript !== ''){
            onChange(finalTranscript);
            resetTranscript();
        }
    }, [interimTranscript, finalTranscript]);

    //Change the language mode of the speech recognition
    useEffect(() => {
        if(languageMode !== lastLanuageMode && isListening){
            lastLanuageMode = languageMode;
            SpeechRecognition.stopListening();
            onListeningModeChange(false);
            SpeechRecognition.startListening({
                continuous: true,
                language: languageMode,
            });  
        }else if(languageMode !== lastLanuageMode && !isListening){
            lastLanuageMode = languageMode;
        }
        
    }, [languageMode]);

    //Start or stop the speech recognition
    useEffect(() => {
        if(isListening){
            SpeechRecognition.startListening({
                continuous: true,
                language: languageMode,
            });
        } else {
            SpeechRecognition.stopListening();
        }
    }, [isListening]);

    //Check if the browser supports speech recognition
    if(!SpeechRecognition.browserSupportsSpeechRecognition()){
        if(document.querySelector('.microphone-button') !== null)
            document.querySelector('.microphone-button').style.display = 'none';
        return null;
    }

    //Function to open the xkcd comic in a new tab
    const dropTable = () => {
        window.open("https://imgs.xkcd.com/comics/exploits_of_a_mom.png", "_blank") || window.location.replace("https://imgs.xkcd.com/comics/exploits_of_a_mom.png");
    };
}

export default Dictaphone;