import * as apiCalls from "./apiCalls.js";
import * as constVariable from "./constVariable.js";
import * as util from "./util.js";

/**
 * This function gets the preview image for the given document ID and page number.
 * @param {number} docid - The document ID for which the preview image is to be fetched.
 * @returns {Promise<string>} - A promise that resolves with the base64 URL of the preview image.
 */

const pdfPathCallback = (response, abcd) => {
    // create a Blob from the PDF Stream
    const file = new Blob([response.data], {
        type: "application/pdf",
    });

    let base64String;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
        base64String = reader.result;
        abcd(base64String);
    };
};

// callback function for getPDFDocument
const openPrintWindowCallback = (response) => {
    // create a Blob from the PDF Stream
    const file = new Blob([response.data], {
        type: "application/pdf",
    });
    // build a URL from the file
    const fileURL = URL.createObjectURL(file);
    // open the URL on new Window

    // Workaround for Safari, only opens the PDF in a new tab
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        setTimeout(() => window.open(fileURL, "_blank"));
        return;
    }

    // Create a hidden iFrame
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    // Set the source of the iFrame to the PDF file
    iframe.src = fileURL;

    // When the iFrame has loaded, print the PDF
    iframe.onload = function() {
        // Print the PDF
        iframe.contentWindow.print();
    };
};

// callback function for openPDFWindow
const openPDFWindowCallback = (response, pdfWindow) => {
    // create a Blob from the PDF Stream
    const file = new Blob([response.data], {
        type: "application/pdf",
    });
    // build a URL from the file
    const fileURL = URL.createObjectURL(file);
    console.log(fileURL);
    // open the URL on new Window
    pdfWindow.location.href = fileURL;
}

// callback function for getPDFDocument
export async function getPreviewfromBackend(docid, pageNumber) {
    try {
        const response = await apiCalls.getPreviewfromBackend(docid, pageNumber);

        // convert the Blob into a Base64 URL to be used as an image source
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result); // convert the blob to base64
            reader.onerror = reject;
            reader.readAsDataURL(response.data); // start the conversion
        });
    } catch (error) {
        util.showToast("An error occurred while fetching the preview image.", util.ToastType.ERROR);
        return null; // return null in case of error
    }
}

// callback function for getPDFPath
export async function getPDFPath(docid, abcd) {
    try {
        await apiCalls.getPDFPath(docid, abcd, pdfPathCallback);
    } catch (error) {
        util.showToast("An error occurred while fetching the PDF path.", util.ToastType.ERROR);
    }
}

// callback function for openPrintWindow
export async function openPrintWindow(docid){
    try {
        await apiCalls.getPDFDocument(docid, openPrintWindowCallback);
    } catch (error) {
        util.showToast("An error occurred while opening the print window.", util.ToastType.ERROR);
    }
}


/**
 * This function gets the documents from the backend for the given FA number.
 * @param {number} FAnumber - The FA number for which the documents are to be fetched.
 * @returns {Promise<void>} - A promise that resolves when the documents are fetched.
 */
export async function getDocumentsFromFA(FAnumber) {
    try {
        const response = await apiCalls.getDocumentsFromFA(FAnumber);

        constVariable.setCurrentData(response.data); // Assuming axios automatically parsed the JSON.
        // check if there are no documents
        if (constVariable.getCurrentData() === null) {
            //display the current FA number
            document.querySelector(".current-FA").innerHTML = FAnumber;
            //hide the NVFA message if it is displayed
            document.querySelector(".NVFA").style.display = "none";
            // display a message that no documents were found
            document.querySelector(".NDF").style.display = "inline";
            document.querySelector(".current-Status").innerHTML = "";
            // clear the table
            util.clearTable();
            return;
        }
    } catch (error) {
        util.showToast("An error occurred while fetching the documents.", util.ToastType.ERROR);
        return { error };
    }

    // add the FAnumber to currentData
    constVariable.getCurrentData().FAnumber = FAnumber;

    // count the loaded previews
    let loadedPrewiews = 0;

    // get the preview images for the documents and set them in the currentData
    constVariable.getCurrentData().forEach((item) => {
        item.preview = document.createElement("img");
        item.preview.style.width = "100%"; // Set the desired width or leave it to CSS

        // get the preview image from the backend and set previewIndex to 0
        getPreviewfromBackend(item.id,"0").then((preview) => {
            if (preview) {
                item.preview.src = preview;
                item.previewIndex = 1;
            } else {
                item.preview = null;
            }
            loadedPrewiews++;
            if (loadedPrewiews === constVariable.getCurrentData().length) {
                // set the displayed data
                util.setInfoLine(constVariable.getCurrentData());
            }
        });
    });
}

/**
 * This function opens the PDF window for the given PDF ID.
 * @param {number} pdfID - The ID of the PDF to be opened.
 * @param {Window} pdfWindow - The window in which the PDF is to be opened.
 */
export const openPDFWindow = (pdfID, pdfWindow) =>{
    apiCalls.openPDFWindow(pdfID, pdfWindow, openPDFWindowCallback);
};