import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as favourite from '../modules/favouriteManager';

/**
 * old name FavouriteContainer component for BookmarkContainer.
 * @returns The FavouriteContainer component
 * */
const FavouriteContainer = () => {

    // Constants declaration
    const { t } = useTranslation();
    const navigate = useNavigate();

    /**
     * This function is used to navigate to the FA page.
     * @param faNumber - The FA number.
    */
    const navigateEvent = (faNumber) => {
        navigate("/fa/" + faNumber);
    }

    // Generate the favourite list
    useEffect(() => {
        favourite.generateFavouriteList(navigateEvent);
        
        var myOffcanvas = document.getElementById('offcanvasExample');
        myOffcanvas.addEventListener('show.bs.offcanvas', () => {
            favourite.generateFavouriteList(navigateEvent);
        })
    }, []);

    // Return the FavouriteContainer component
    return (
        <div className="offcanvas offcanvas-end favCSS" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasExampleLabel">{t("favourite")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <div className="favouriteFA"></div>
            </div>
        </div>
    );
};

export default FavouriteContainer;