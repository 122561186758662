import {useTranslation} from "react-i18next";
import SaveText from "./SaveText";

/**
 * ShowFaStatus component
 * @returns The ShowFaStatus component
 */
const ShowFaStatus = () => {
    //Constants declaration
    const { t } = useTranslation();
    // Return the current FA number and its status
    return (
        <div>
            {/* show current FAnumber and it's status */}
            <div className="fa-info-line">
                {/* translate "FA" dynamic */}
                <div className="text-container-content">
                    {t("FA")}:{" "}
                </div>
                {/* current FA */}
                <div className="current-FA text-container-content">
                    NAN
                </div>
                {/* for translation purpose "Not a valid FA" (only shows if needed) */}
                <div hidden className="NVFA">
                    {t("NVFA")}
                </div>
                {/* translate "Status" dynamic */}
                <div className="text-container-content">
                    {" | " + t("Status")}:{" "}
                </div>
                {/* current status */}
                <div className="current-Status text-container-content">
                    NAN
                </div>
                <SaveText />
                {/* for translation purpose "No documents found" (only shows if needed) */}
                <div hidden className="NDF">
                    {t("NDF")}
                </div>
            </div>
        </div>
    );
}

export default ShowFaStatus;