import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LangDetec from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import {router} from "./router";
import {RouterProvider} from "react-router-dom";
import {OidcProvider} from "@axa-fr/react-oidc";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "flag-icon-css/css/flag-icons.min.css";

// Initialize i18n
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LangDetec)
    .use(HttpApi)
    .init({
        // german, english
        supportedLngs: ["de", "en"],
        detection: {
            order: ["cookie"],
            caches: ["cookie"],
        },
        backend: {
            loadPath: "/assets/locales/{{lng}}.json",
        },
    });

// OIDC configuration
const oidc_configuration = {
    client_id: "dokview-web-frontend-dev",
    redirect_uri: window.location.origin + "/authentication/callback",
    silent_redirect_uri:
        window.location.origin + "/authentication/silent-callback",
    scope: "openid profile email offline_access",
    authority: "https://auth.0x1b.net/realms/dokview/",
    service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: true,
    demonstrating_proof_of_possession: false,
};
// Create a root
const root = ReactDOM.createRoot(document.getElementById("root"));

// Loading markup
const loadingMarkup = (
    <div className="py-4 text-center">
        <h2>Loading..</h2>
    </div>
);

// Render the app
root.render(
    <Suspense fallback={loadingMarkup}>
        <React.StrictMode>
            <OidcProvider configuration={oidc_configuration}>
                <RouterProvider router={router}/>
            </OidcProvider>
        </React.StrictMode>
    </Suspense>
);
