const barrelRollChain = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
// current index in the barrel roll chain
let barrelRollIndex = 0;

/**
 * This function handles the keypress event for the barrel roll easter egg.
 * @param e - The event object.
 */
export const handleKeyPressForBarrelRoll = (e) => {
    // Check if the key pressed is the next key in the barrel roll chain, if not: reset the index
    if (e.keyCode === barrelRollChain[barrelRollIndex]) {
        barrelRollIndex += 1;
    } else {
        barrelRollIndex = 0;

        // Check if the key pressed is the first key in the barrel roll chain, if so: increment the index
        // (this is done so that if the user presses the first (correct) key thrice, the third keypress is 
        // counted as the first keypress in the new chain)
        if (e.keyCode === barrelRollChain[barrelRollIndex]) {
            barrelRollIndex += 1;
        }
    }

    // Check if the barrel roll chain has been completed
    if (barrelRollIndex === barrelRollChain.length) {
        console.log("Konami Code activated");
        barrelRollIndex = 0;
        document.querySelector("#root").classList.toggle("barrelRoll");

        // Reset the barrel roll class after ~4 seconds so that the page can be rolled again without having to
        // trigger the code twice
        setTimeout(() => {
            document.querySelector("#root").classList.toggle("barrelRoll");
        }, 4100);
    }
};