import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./modules/jQuery";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/Rest/switch.css";
import "./styles/Rest/Homepage.css";
import { handleKeyPressForBarrelRoll } from "./modules/eastereggs.js";
import { useTheme } from "./modules/theme.js";
import MenuBar from "./components/MenuBar";
import FavouriteContainer from "./components/FavouriteContainer.jsx";
import FaSearchBar from "./components/FaSearchBar";
import DocSearchBar from "./components/DocSearchBar";
import DocList from "./components/DocList.jsx";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import * as adminManager from "./modules/adminManager";

/**
 * This function generates the homepage with all content.
 * @returns {Element} - Returns the homepage element.
 */
function HomePage() {
    //TODO: Change FA number, so that just numeric are allowed and more than 8 numbers
    const FA_ID_maxInput = 10;

    const { t } = useTranslation();

    // Dark and Light Theme switch
    const { isLightMode, handleThemeToggle } = useTheme();

    // Check if user is admin and set isAdmin
    const { accessTokenPayload } = useOidcAccessToken();
    let role = accessTokenPayload && accessTokenPayload.resource_access['dokview-web-frontend-dev'] && accessTokenPayload.resource_access['dokview-web-frontend-dev'].roles.includes('dokview_admin') ? "Admin" : "User";
    adminManager.setIsAdmin(role === "Admin" ? true : false);
    
    // for dynamic routing (URL)
    const { faid } = useParams();

    // added for searchhistory
    const [recentSearches, setRecentSearches] = useState([]);
    const [showRecentSearches, setShowRecentSearches] = useState(false);
    const searchRef = useRef(null);

    // The language mode for the speech recognition
    const [isListening, setIsListening] = useState(false);

    /**
     * This function checks key presses for Barrel Roll
     */
    useEffect(() => {
        document.onkeydown = handleKeyPressForBarrelRoll;
    });

    // return the JSX for the HomePage component
    return (
        <div id="root" className={isLightMode ? "dark-mode bodyBackground" : "light-mode bodyBackground" }>
            <MenuBar isLightMode={isLightMode} handleThemeToggle={handleThemeToggle} />
            <div className="logo">
                {/* Logo */}
                <Link to="/">
                    <img src={process.env.PUBLIC_URL + "/assets/logo/logo.svg"}
                        alt="Logo"
                        className="logo-image"
                    />
                </Link>
            </div>
            <FaSearchBar faid={faid}
                isListening={isListening}
                setIsListening={setIsListening}
                showRecentSearches={showRecentSearches}
                setShowRecentSearches={setShowRecentSearches}
                searchRef={searchRef}
                recentSearches={recentSearches}
                setRecentSearches={setRecentSearches}
                FA_ID_maxInput={FA_ID_maxInput} />
            {/* show current FAnumber and it's status */}
            <div className="fa-info-line">
                {/* Favourite FA icon */}
                <div className="text-container-content">
                    <img className="favourite-icon-FA" src={process.env.PUBLIC_URL + '/assets/icons/favourite_notfilled.svg'}
                        alt="Favourite" /*onClick={(e) => fav.handleFAFavIconClick(e)}*//>
                </div>
                {/* translate "FA" dynamic */}
                <div className="text-container-content">
                    {t("FA")}:{" "}
                </div>
                {/* current FA */}
                <div className="current-FA text-container-content">
                    NAN
                </div>
                {/* for translation purpose "Not a valid FA" (only shows if needed) */}
                <div hidden className="NVFA">
                    {t("NVFA")}
                </div>
                {/* translate "Status" dynamic */}
                <div className="text-container-content">
                    {" | " + t("Status")}:{" "}
                </div>
                {/* current status */}
                <div className="current-Status text-container-content">
                    NAN
                </div>
                {/* for translation purpose "No documents found" (only shows if needed) */}
                <div hidden className="NDF">
                    {t("NDF")}
                </div>
            </div>
            {/* Document search bar */}
            <DocSearchBar/>
            {/* Document list */}
            <DocList />
            {/* Toast container */}
            <div aria-live="polite" aria-atomic="true" className="position-relative">
                <div className="toast-container position-fixed top-0 end-0 p-3"></div>
            </div>
            {/* Favourite container */}
            <FavouriteContainer />
        </div>
    );
}

export default HomePage;