import i18n from 'i18next';
import * as constVariable from './constVariable.js';
import bootstrap from "bootstrap/dist/js/bootstrap.js";
import Fuse from "fuse.js";
import * as documentManager from "./documentManager.js";

/**
 * This function sets the FA and status in the header.
 * @param {Array} data - The data of which the FA and status are to be set.
 */
export function setInfoLine(data) {
    
    const currentFAElement = document.querySelector(".current-FA");
    // check if currentFAElement exists
    if (currentFAElement) {
        currentFAElement.innerHTML = data.FAnumber;
    } else {
        showToast("Element with class 'current-FA' not found.", ToastType.ERROR);
    }
    const currentStatusElement = document.querySelector(".current-Status");
    // check if currentStatusElement exists
    if (currentStatusElement) {
        currentStatusElement.innerHTML = data.status;
    } else {
        showToast("Element with class 'current-Status' not found.", ToastType.ERROR);
    }

    const NVFAElement = document.querySelector(".NVFA");
    // check if NVFAElement exists
    if (NVFAElement) {
        NVFAElement.style.display = "none";
    } else {
        showToast("Element with class 'NVFA' not found.", ToastType.ERROR);
    }

    const NDFElement = document.querySelector(".NDF");
    // check if NDFElement exists
    if (NDFElement) {
        NDFElement.style.display = "none";
    } else {
        showToast("Element with class 'NDF' not found.", ToastType.ERROR);
    }
}

/**
 * This function searches in the current documents for the given search query and regenerates the list.
 * @param {string} searchquery - The search query for which the documents are to be searched.
 */
export function searchCurrentData(searchquery) {
    // check if the current data is not null and the length is 0
    if (constVariable.getCurrentData() === null || constVariable.getCurrentData().length === 0) {
        return;
    }

    // list for the search results
    let searchResult = [];
    // search in the current data for the search query
    for (const item of constVariable.getCurrentData()) {
        // check if the document name includes the search query
        if (item.name.includes(searchquery)) {
            // add the document to the search result
            searchResult.push(item);
        }
    }
    searchResult.FAnumber = constVariable.getCurrentData().FAnumber;

    // set the search result as the displayed data
    setInfoLine(constVariable.getCurrentData());
}

/**
 * This function clears the document list.
 */
export function clearTable() {
    constVariable.setCurrentData(null);
}

/**
 * This function gets the documents for the given FA number and saves them in currentData.
 * @param {number} FAnumber - The FA number for which the documents are to be fetched.
 */
export function getDocuments(FAnumber) {
    // check if the currentData is not null
    if (constVariable.getCurrentData() != null) {
        // check if the FAnumber is the same as the currentData, if so, return
        if (FAnumber === constVariable.getCurrentData().FAnumber) {
            // generate the table with the documents from the currentData and return
            // set the search result as the displayed data
            setInfoLine(constVariable.getCurrentData());
            return;
        }

        // check if the FA is already in the recentData
        for (let i = 0; i < constVariable.recentData.length; i++) {
            if (constVariable.recentData[i].FAnumber === FAnumber) {
                // swap the currentData with the data from the recentData
                let tmp = constVariable.recentData[i];
                constVariable.recentData[i] = constVariable.getCurrentData();
                constVariable.setCurrentData(tmp);
                // set the displayed data
                setInfoLine(constVariable.getCurrentData());
                // exit the function, data is loaded
                return;
            }
        }

        // new data needs to be loaded
        // add the currentData to the recentData
        if (constVariable.getCurrentData() !== null) {
            constVariable.recentData.push(constVariable.getCurrentData());
        }
        // check if the recentData has to many entries
        if (constVariable.recentData.length > 3) {
            // remove the oldest entry
            constVariable.recentData.shift();
        }
    }
    // get the new documents from the backend
    documentManager.getDocumentsFromFA(FAnumber);
}

// define the types for the toast notifications
export const ToastType = {
    INFO: "ToastType.INFO",
    SUCCESS: "ToastType.SUCCESS",
    ERROR: "ToastType.ERROR"
}

/**
 * Displays a toast notification with the given title and body.
 * @param {string} message - The body content of the toast notification.
 * @param {ToastType} type - The type of the toast notification. Can be ToastType.SUCCESS, ToastType.ERROR, or ToastType.INFO.
 */
export const showToast = (message, type) => {
    // Define the toast container and create a new toast element
    const toastContainer = document.querySelector(".toast-container");
    const toastEl = document.createElement("div");
    toastEl.classList.add("toast", "align-items-center");

    // add styles based on the type
    if (type === ToastType.ERROR) {
        toastEl.classList.add("text-bg-danger");
    } else if (type === ToastType.SUCCESS) {
        toastEl.classList.add("text-bg-success");
    } else if (type === ToastType.INFO) {
        toastEl.classList.add("text-bg-info");
    }

    toastEl.setAttribute("role", "alert");
    toastEl.setAttribute("aria-live", "assertive");
    toastEl.setAttribute("aria-atomic", "true");
    toastEl.innerHTML = `
        <div class="d-flex">
            <div class="toast-body">
                ${message}
            </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
    `;
    toastContainer.appendChild(toastEl);

    // show the toast
    const toast = new bootstrap.Toast(toastEl);
    toast.show();
};

/**
 * This function fetches the autocomplete options for the given value from the current data.
 * @param {string} value - The value for which the autocomplete options are to be fetched.
 * @returns {*[]} - The autocomplete options.
 */
export function fetchAutocompleteOptions(value) {
    // check if the value is empty
    if(value === "") {
        return constVariable.getCurrentData()?.map((item) => item.name);
    }
    // define the fuse options
    const fuseOptions = {
        includeScore: true,
        includeMatches: true,
        keys: ["name"]
    };

    // create the initial data for the fuse search
    let initalData = [];
    // add the names from the current data to the initial data
    constVariable.getCurrentData()?.forEach((item) => {
        initalData.push({
            name: item.name
        });
    });

    // create the fuse object and search for the value
    const fuse = new Fuse(initalData, fuseOptions);
    const result = fuse.search(value);
    const results = result.filter((item) => item.score < constVariable.fuzzyStringThreshold).map((item) => item.item.name);

    return results;
}

/**
 * Translates the key to the current language, just for better readability of the code.
 * @param key - The key to be translated
 * @returns The translated key
 */
export function translate(key) {
    return i18n.t(key);
}

/**
* This function generates a color based on the user information.
* @param {string} - unique user information
* @returns {string} - Color from the predefined list
*/
export const getColorByHash = (input) => {
    // default color
   let returnColor = "black"

   // check if the input is not null
   if(input !== null){
       let hash = 0;
       for(let i = 0; i < input.length; i++) {
           hash = input.charCodeAt(i) + ((hash << 5) - hash);
           hash = hash & hash;
       }
       // define the colors
       const colors = [
           "red",
           "green",
           "blue",
           "orange",
           "purple",
           "pink",
           "yellow",
           "brown",
           "black",
       ];

         // return the color based on the hash
       returnColor = colors[hash % colors.length];
   }
   return returnColor;
};