import * as util from "../modules/util";
import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";

/**
 * This component represents the document search bar.
 * @param searchQuery - The search query.
 * @param setSearchQuery - The function to set the search query.
 * @returns {JSX.Element} - The document search bar.
 */
const DocSearchBar = ({ searchQuery, setSearchQuery }) => {
    // added for doc search auto complete
    const { t } = useTranslation();
    // added for doc search auto complete
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const autoComp = useRef(null);

    // added for doc search auto complete
    useEffect(() => {
        /**
         * This function handles the click outside the search field and closes the recent searches and  doc search autocomplete.
         * @param event - The event object.
         */
        const handleClickOutside = (event) => {
            if (autoComp.current &&
                !autoComp.current.contains(event.target)) {
                setShowAutocomplete(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    /**
     * This function handles the input change in the document search field.
     * @param event - The event object.
     */
    const handleDocSearchInputChange = (event) => {
        const { value } = event.target;
        setSearchQuery(value);
        setAutocompleteOptions(util.fetchAutocompleteOptions(value));
        setShowAutocomplete(true);
    };

    /**
     * This function handles the document search form submission.
     * @param event - The event object.
     */
    const handleDocSearch = (event) => {
        event.preventDefault();
        // Perform search based on searchQuery
        util.searchCurrentData(searchQuery);
        // Update the value of the text field with the selected option
        const docSearchInput = document.querySelector(".doc-search");
        docSearchInput.value = searchQuery;
        // Hide autocomplete options
        setShowAutocomplete(false);
    };

    // added for doc search auto complete
    const docSearchElement = document.querySelector(".doc-search-form"); 
    const autowidth = docSearchElement ? docSearchElement.offsetWidth : 0;

    // Return the document search bar
    return (
        <div className="doc-search-bar">
            {/* Document search form */}
            <form className="doc-search-form" onSubmit={event => handleDocSearch(event)}>
                <div className="wrapper sb-height">
                    <input id="input_doc"
                        placeholder={t("doc_name")}
                        className="doc-search"
                        value={searchQuery}
                        onChange={handleDocSearchInputChange}
                        onFocus={() => {
                            handleDocSearchInputChange({target: {value: searchQuery}});
                            setShowAutocomplete(true);
                        }}
                        onWheel={(e) => e.target.blur()}
                    />
                    <div className="suffix" onClick={event => handleDocSearch(event)}>
                        {t("suche")}
                    </div>
                </div>
                {showAutocomplete && (
                    <ul className="autocomplete-options" ref={autoComp} style={{width:`${autowidth}px`}}>
                        {autocompleteOptions?.map(option => (
                            <li className="search-option"
                                key={option} onClick={(e) => {
                                e.preventDefault();
                                setSearchQuery(option); // Set the search query to the selected option
                                util.searchCurrentData(option); // Perform search based on the selected option
                                setShowAutocomplete(false); // Hide the autocomplete dropdown
                            }}>
                                {option}
                            </li>
                        ))}
                    </ul>
                )}
            </form>
        </div>
    );
}

export default DocSearchBar;