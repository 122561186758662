import Avatar from "react-avatar";
import { getColorByHash } from "../modules/util";
import CommentForm from "./CommentForm";
import { useTranslation } from "react-i18next";


/**
// Comment component
// @param comment - The comment
// @param children - The children of the comment
// @param addComment - The function to add a comment
// @param deleteComment - The function to delete a comment
// @param updateComment - The function to update a comment
// @param activeComment - The active comment
// @param setActiveComment - The function to set the active comment
// @param uid - The user id
// @param parentId - The parent id. Default is null
// @returns The Comment component
*/
const Comment = ({ comment, children, addComment, deleteComment, updateComment, activeComment, setActiveComment, uid, parentId = null }) => {

    // Constants declaration
    const { t } = useTranslation();
    const canDelete = uid === comment.uid;
    const canEdit = uid === comment.uid;
    const isReplying = activeComment && activeComment.id === comment.id && activeComment.type === "reply";
    const isEditing = activeComment && activeComment.id === comment.id && activeComment.type === "edit";
    const replyId = parentId ? parentId : comment.id;

    // Format the date of the comment
    // @param date - The date of the comment
    // @returns The formatted date
    const formatDate = (date) => {
        const d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
    }

    // Return the comment with the avatar, the name, the date, the comment, the actions and the children
    return (
        <>
            <div className="mt-2 px-2">
                <Avatar color={getColorByHash(comment.uid)}
                    name={comment.name}
                    size="60"
                    round={true} />
            </div>
            <div className="flex-grow-1 flex-shrink-1">
                <div>
                    <div className="d-flex mx-2 justify-content-between">
                        <p className="mb-1">
                            {comment.name} <span className="small text-end">{formatDate(comment.created)}</span>
                        </p>
                    </div>
                    <p className="mb-1 mx-2 text-start text-break">
                        {comment.comment}
                    </p>
                    <div className="d-flex mx-2">
                        <div className="comment-action small me-2" onClick={() => setActiveComment({id: comment.id, type: "reply", to: comment.name})}>{t("reply")}</div>
                        {canEdit && <div className="comment-action small me-2" onClick={() => setActiveComment({id: comment.id, type: "edit"})}>{t("edit")}</div>}
                        {canDelete && <div className="comment-action small" onClick={() => deleteComment(comment.id)} >{t("delete")}</div>}
                    </div>
                    {isReplying && (
                        <CommentForm label={t("reply")} handleSubmit={(text) => addComment(text, replyId)} initialText={"@"+comment.name+" "} hasCancelButton handleCancel={() => setActiveComment(null)} />
                    )}
                    {isEditing && (
                        <CommentForm label={t("save")} handleSubmit={(text) => updateComment(text, comment.id)} initialText={comment.comment} hasCancelButton handleCancel={() => setActiveComment(null)} />
                    )}
                    {children.length > 0 && (
                        <>
                            {children.map(child => (
                                <div className="d-flex flex-start mt-4" key={child.id} >
                                    <Comment comment={child} children={[]} addComment={addComment} deleteComment={deleteComment} parentId={comment.id} uid={uid} updateComment={updateComment} activeComment={activeComment} setActiveComment={setActiveComment} />
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Comment;