import * as apiCalls from "./apiCalls.js";
import * as util from "./util.js";
import * as constVariable from "./constVariable.js";

// Default value for isAdmin
let isAdmin = false;

// Callback function for getIsAdmin
export function getIsAdmin() {
    return isAdmin;
}

// Callback function for setIsAdmin
export function setIsAdmin(value) {
    isAdmin = value;
}

// Callback function for getIsAdmin
const setDefaultValuesCallback = (response) => {
    let abc = response.data;

    constVariable.setDefaultValuesTheme(abc.theme === "false" ? constVariable.theme.LIGHT : constVariable.theme.DARK);
    constVariable.setDefaultValuesLanguage(abc.language === "en" ? constVariable.language.EN : constVariable.language.DE);
    constVariable.setDefaultValuesDocumentListSize(abc.listsize);
}

// Callback function for getIsAdmin
export async function getDefaultValues() {
    let error = await apiCalls.getDefaultValues(setDefaultValuesCallback);

    if(error){
        util.showToast("An error occurred while loading default values.\n" + error, util.ToastType.ERROR);
    }
}

/**
 * This function sets the default values for the app.
 * @param {string} themeDefault - The default theme for the app.
 * @param {string} languageDefault - The default language for the app.
 * @param {number} documentListSizeDefault - The default size for the document list.
 */
export async function setDefaultValues(themeDefault, languageDefault, documentListSizeDefault) {
    try {
        // Create the data object
        const datanew = {
            theme: themeDefault,
            language: languageDefault,
            documentListSize: documentListSizeDefault
        };

        // Create headers for the post request
        const config = {
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        // Send the post request
        await apiCalls.setDefaultValues(datanew, config);
    } catch (error) {
        util.showToast("An error occurred while setting default values.\n" + error, util.ToastType.ERROR);
        return { error };
    }
}