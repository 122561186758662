import DocTile from "./DocTile";
import {getCurrentData} from "../modules/constVariable";
import {useEffect, useState} from "react";
import * as constVariable from "../modules/constVariable";

/**
 * This component represents the document list.
 * @param searchQuery - The search query.
 * @param setPdfpath - The function to set the PDF path.
 * @param setpdfID - The function to set the PDF ID.
 * @returns {JSX.Element} - The document list.
 */

const DocListDocList = ({ searchQuery, setPdfpath, setpdfID }) => {
    // Get the current data
    const [currentData, setCurrentData] = useState(getCurrentData());

    // Refresh the current data
    useEffect(() => {
        // on event refreshCurrentData call setCurrentData
        window.addEventListener("refreshCurrentData", () => setCurrentData(constVariable.getCurrentData()));
    }, []);

    // Render the document list
    return (
        <>
            {currentData && (searchQuery ? currentData.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase())) : currentData).map((doc) => {
                return (
                    <DocTile key={doc.name}
                             item={doc}
                             setPdfpath={setPdfpath}
                             setpdfID={setpdfID}
                    />
                )
            })}
        </>
    );
}

export default DocListDocList;