import * as util from './util.js';

/**
 * Creates a share dialog with a QR code, a link to the FA, and a button to send the FA via email
 * @param {number} - FA number
 * @param {boolean} isLightMode - Whether the mode is light or dark
 * @returns 
 */
const shareDialog = (FA, isLightMode) => {
    return `
        <div class=${isLightMode ? "dark-mode" : "light-mode"}>
            <div class="qr-code">
                <canvas id="canvas"></canvas>
            </div>
            <div class="wrapper">
                <div class="share-link-textfield">
                    <input id="copy-link-field" type="text" value="https://dev.dokview.com/fa/${FA}" readonly>
                </div>
                <div id="share-copy" class="suffix" onclick="navigator.clipboard.writeText(document.getElementById('copy-link-field').value)" data-bs-container="body" data-bs-placement="top" data-bs-toggle="popover" data-bs-content="${util.translate('copied!')}" data-bs-custom-class="share-popover">
                    ${util.translate("copyLink")}
                </div>
            </div>
            <div class="send-mail-button">
                <a href="mailto:?subject=${util.translate("share_email_subject")}&body=${util.translate("share_email_body")} https://dev.dokview.com/fa/${FA}">
                    Send via Email
                </a>
            </div>
        </div>
        `;
};

export default shareDialog; 