import * as constVariable from './constVariable.js';
import * as util from './util.js';
import * as apiCalls from './apiCalls.js';

/**
 * HTTP Post Method: Setting User's favourite document
 * @param {Number} faIdPostFunction
 */
export const postFavourite = async(faIdPostFunction) => {
    let response = await apiCalls.postFavourite(faIdPostFunction);
    if(response){
        util.showToast("An error occurred while setting favourite.\n" + response, util.ToastType.ERROR);
    }
}

/**
 * HTTP Delete Method: Deleting User's favourite FA
 * @param {Number} faIdPostFunction
 */
export const deleteFavourite = async(faIdPostFunction) => {
    let response = await apiCalls.deleteFavourite(faIdPostFunction);
    if(response){
        util.showToast("An error occurred while deleting favourite.\n" + response, util.ToastType.ERROR);
    }
}

/**
 * This Function is a slimmer version of getFavourite. GetFavourite is in HomePage body.
 * @returns {Number[]} Returns an array with the favourite FA-Numbers.
 */
export async function getFavouriteForFavFA(){
    try {
        const response = await apiCalls.getFavourite();

        const dataArray = JSON.parse(response.data); // set dataArray to currentData
        // check if currentData is null
        if (dataArray.length === 0) {
            return [];
        }else{
            let onlyFANumbers = []; // Array for FA-Numbers

            dataArray.forEach(faNumber => {
                onlyFANumbers.push(faNumber); // if documentId is null or 0, save only the FA-Number
            });

            return onlyFANumbers;
        }
    } catch (error) {
        util.showToast("An error occurred while fetching the favourite FA-Numbers.\n" + error, util.ToastType.ERROR);
        return {error};
    }
}

/**
 * This function handles the click on the FAFavIcon, adds or removes the FA-Number from the favourite list and changes the icon accordingly.
 * @param e - The event object.
 */
export const handleFAFavIconClick = (setIsFavourite) => {
    let currentData = constVariable.getCurrentData();
    //get FAFavlist and check if the current FA-Number is in the list
    getFavouriteForFavFA().then(tmp => {
        if (currentData === null || currentData.length === 0) {
            return;
        }
        const isFav = tmp.some(element => element == currentData.FAnumber);

        if (isFav) {
            // delete the current FA-Number from the favourite list
            deleteFavourite(currentData.FAnumber, 0);
            util.showToast(util.translate("fa_unsaved"), util.ToastType.INFO);
        } else {
            // add the current FA-Number to the favourite list
            postFavourite(currentData.FAnumber, 0);
            util.showToast(util.translate("fa_saved"), util.ToastType.INFO);
        }
        setIsFavourite(!isFav);
    });
}

/**
 * This Function is a slimmer version of getFavourite. GetFavourite is in HomePage body.
 * @returns {Number[]} Returns an array with the favourite FA-Numbers.
 */
export const getFavouriteForFavFAIcon = async () => {
    try {
        const response = await apiCalls.getFavourite();

        const dataArray = JSON.parse(response.data);
        if (dataArray.length === 0) {
            return [];
        } else {
            let onlyFANumbers = []; // Array for FA-Numbers

            dataArray.forEach(faNumber => {
                onlyFANumbers.push(faNumber)
            });

            return onlyFANumbers;
        }
    } catch (error) {
        util.showToast("An error occurred while fetching the favourite FA-Numbers.\n" + error, util.ToastType.ERROR);	
        return {error};
    }
}

/**
 * This function generates the favourite list and adds it to the offcanvas.
 * @returns {Object} On error, returns an object with the error message.
 */
export const generateFavouriteList = async(navigateEvent) => {
    try {
        const response = await apiCalls.getFavourite();
        // parse the response data
        const dataArray = JSON.parse(response.data);
        // create ul element
        const ulList = document.createElement('ul');
        // create var FA numbers
        const faNumberHeader = document.createElement('h3');
        faNumberHeader.textContent = util.translate("Fertigungsauftragsnummer");
        // create var documents

        if (dataArray.length === 0) {
            // add FANumbers to the ulList
            ulList.appendChild(faNumberHeader);
            //create
            let divFav = document.createElement('div');
            divFav.className = "favBox";
            
            ulList.appendChild(faNumberHeader);
            divFav.appendChild(document.createElement('li')).textContent = util.translate("NoFav");
            ulList.appendChild(divFav);

            let favouriteFAtext = document.querySelector(".favouriteFA");
            favouriteFAtext.innerHTML="";                
        
            favouriteFAtext.appendChild(ulList);

        }else{
            // add FANumbers to the ulList
            ulList.appendChild(faNumberHeader);
            // create 1 div for all items that contains 1 FANumber
            let divFav = document.createElement('div');
            divFav.className = "favBox";

            let onlyFANumbers = []; // Array for FA numbers

            dataArray.forEach(faNumber => {
                onlyFANumbers.push(faNumber)
            });
            
            // create for each FANumber a listItem that can be clicked to search for the FANumber
            if (onlyFANumbers.length !== 0) {
                onlyFANumbers.forEach(faNumber => {
                    let listItem = document.createElement('li');
                    let divFavItemText = document.createElement('div');
                    divFavItemText.className = "favItemDiv";
                    let divFavItemicon = document.createElement('div');
                    divFavItemicon.className = "favItemDiv";

                    //set the text of the listItem
                    divFavItemText.textContent = faNumber;
                    // add the event listener to search for the FANumber
                    divFavItemText.addEventListener('click', (e) => {
                        // handleRecentSearchClick(faNumber.toString(), e);
                        navigateEvent(faNumber.toString());
                    });
                    //set Attribute to dismiss the offcanvas
                    divFavItemText.setAttribute("data-bs-dismiss", "offcanvas");
                    divFavItemText.style.cursor = "pointer";
                    // add the text to the list item
                    listItem.appendChild(divFavItemText);
                    //add the | to the list item
                    listItem.appendChild(document.createTextNode(" | "));

                    // create image for the favourite icon
                    let div = document.createElement('div');
                    div.className = "favIconImg";
                    div.innerHTML = util.translate("saved_fa_in_fav_offcanvas");

                    //add the event listener to add or remove the document from the favourites
                    div.addEventListener("click", (e) =>{
                        if (e.target.innerHTML.includes(util.translate("saved_fa_in_fav_offcanvas"))){
                            e.target.innerHTML = util.translate("unsaved_fa_in_fav_offcanvas");
                            deleteFavourite(faNumber);
                        } else {
                            e.target.innerHTML = util.translate("saved_fa_in_fav_offcanvas");
                            postFavourite(faNumber);
                        }
                    });
        
                    //add the icon to the list item and the list item to the list
                    divFavItemicon.appendChild(div);
                    listItem.appendChild(divFavItemicon);

                    // add listItem to the divFav
                    divFav.appendChild(listItem);
                });
            } else {
                divFav.appendChild(document.createElement('li')).textContent = util.translate("NoFav");
            }
            // add divFav to the ul list
            ulList.appendChild(divFav);

            let favouriteFAtext = document.querySelector(".favouriteFA");
            favouriteFAtext.innerHTML="";
            favouriteFAtext.appendChild(ulList);
        }
    } catch (error) {
        util.showToast("An error occurred while fetching the favourite FA-Numbers.\n" + error, util.ToastType.ERROR);
        return {error};
    }
}