// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Width and height */
::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px; /* Height of the scrollbar */

    /*SHOULD MATCH THE BACKGROUND COLOR OF THE ACTUAL PAGE!!! LIKE LIGHT MODE OR BLACKMODE!!!*/
    background-color: #ffffff; /* Background color of the scrollbar track */
}

/* Track */
::-webkit-scrollbar-track {
    background-color: rgba(92, 88, 88, 0.47); /* Background color of the scrollbar track */
    border-left: 1px solid black;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #494949; /* Color of the scrollbar handle */
    border-radius: 6px; /* Rounded corners for the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #73aa95; /* Color of the scrollbar handle on hover */
    border: 1px solid black;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Rest/ScrollBar.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,UAAU,EAAE,2BAA2B;IACvC,WAAW,EAAE,4BAA4B;;IAEzC,0FAA0F;IAC1F,yBAAyB,EAAE,4CAA4C;AAC3E;;AAEA,UAAU;AACV;IACI,wCAAwC,EAAE,4CAA4C;IACtF,4BAA4B;AAChC;;AAEA,WAAW;AACX;IACI,yBAAyB,EAAE,kCAAkC;IAC7D,kBAAkB,EAAE,mCAAmC;AAC3D;;AAEA,oBAAoB;AACpB;IACI,yBAAyB,EAAE,2CAA2C;IACtE,uBAAuB;AAC3B","sourcesContent":["/* Width and height */\r\n::-webkit-scrollbar {\r\n    width: 8px; /* Width of the scrollbar */\r\n    height: 8px; /* Height of the scrollbar */\r\n\r\n    /*SHOULD MATCH THE BACKGROUND COLOR OF THE ACTUAL PAGE!!! LIKE LIGHT MODE OR BLACKMODE!!!*/\r\n    background-color: #ffffff; /* Background color of the scrollbar track */\r\n}\r\n\r\n/* Track */\r\n::-webkit-scrollbar-track {\r\n    background-color: rgba(92, 88, 88, 0.47); /* Background color of the scrollbar track */\r\n    border-left: 1px solid black;\r\n}\r\n\r\n/* Handle */\r\n::-webkit-scrollbar-thumb {\r\n    background-color: #494949; /* Color of the scrollbar handle */\r\n    border-radius: 6px; /* Rounded corners for the handle */\r\n}\r\n\r\n/* Handle on hover */\r\n::-webkit-scrollbar-thumb:hover {\r\n    background-color: #73aa95; /* Color of the scrollbar handle on hover */\r\n    border: 1px solid black;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
