import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import RecentSearches from './LoadHomePage/RecentSearches';
import SearchFA from './LoadHomePage/SearchFA';
import ZugeteilteFA from './LoadHomePage/ZugeteilteFA';
import SearchDocs from './LoadHomePage/SearchDocs';
import './styles/NewHomepage/splitHomePage.css';
import { handleKeyPressForBarrelRoll } from './modules/eastereggs';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import MenuBar from "./components/MenuBar";
import FavouriteContainer from "./components/FavouriteContainer.jsx";
import * as adminManager from "./modules/adminManager";

const NewHomePage = ({ isLightMode, setIsLightMode, handleThemeToggle }) => {
    //define const
    const FA_ID_maxInput = 8;
    const { faid } = useParams();
    const [isListening, setIsListening] = useState(false);
    const [recentSearches, setRecentSearches] = useState([]);
    const [showRecentSearches, setShowRecentSearches] = useState(false);
    const searchRef = useRef(null);

    // Check if user is admin and set isAdmin
    const { accessTokenPayload } = useOidcAccessToken();
    let role = accessTokenPayload && accessTokenPayload.resource_access['dokview-web-frontend-dev']
        && accessTokenPayload.resource_access['dokview-web-frontend-dev'].roles.includes('dokview_admin')
        ? "Admin" : "User";
    adminManager.setIsAdmin(role === "Admin" ? true : false);

    /**
     * This function checks key presses for Barrel Roll
     */
    useEffect(() => {
        document.onkeydown = handleKeyPressForBarrelRoll;
    });

    // return the JSX for the HomePage component
    return (
        <div
            className={isLightMode ? "dark-mode bodyBackground remove-scrollBart" : "light-mode bodyBackground remove-scrollBart"}>
            <div className="homepage-menu-bar">
                <MenuBar isLightMode={isLightMode} handleThemeToggle={handleThemeToggle}/>
            </div>
            <div className="fourTiles text-container-content">
                <div className='top-right'>
                    <RecentSearches/>
                </div>
                <div className='top-left'>
                    <SearchFA 
                        FA_ID_maxInput={FA_ID_maxInput}
                        isLightMode={isLightMode}
                        faid={faid} isListening={isListening}
                        setIsListening={setIsListening}
                        showRecentSearches={showRecentSearches}
                        setShowRecentSearches={setShowRecentSearches}
                        searchRef={searchRef}
                        recentSearches={recentSearches}
                        setRecentSearches={setRecentSearches}/>
                </div>
                <div className='bottom-right'>
                    <ZugeteilteFA/>
                </div>
                <div className='bottom-left'>
                    <SearchDocs className="bottom-right" isLightMode={isLightMode}/>
                </div>
            </div>

            {/* Toast container */}
            <div aria-live="polite" aria-atomic="true" className="position-relative">
                <div className="toast-container position-fixed top-0 end-0 p-3"></div>
            </div>
            {/* Favourite container */}
            <FavouriteContainer />
        </div>
    );
}

export default NewHomePage;
